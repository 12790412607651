import {AlertSeverity, ButtonLinkCancel, ButtonValidate, Checkbox, FieldBlock, FlexContent, FlexContentDirection, FlexContentSpacing, Form, FormLayoutColumns, FormLayoutRows, FormValidationType, InputText, InputTextNumber, ModalActions, ModalContent, ModalHeader, ModalHeaderTitle, ModalNew, Select} from "@sirdata/ui-lib";
import React, {FormEvent, FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {session} from "../../../api/ApiSession";
import {Category} from "../../../api/model/audience/category/Category";
import {CategoryField} from "../../../api/model/audience/category/CategoryField";
import {CategoryGroup} from "../../../api/model/audience/category/CategoryGroup";
import {CategoryGroupField} from "../../../api/model/audience/category/CategoryGroupField";
import {CategoryTaxonomy} from "../../../api/model/audience/category/CategoryTaxonomy";
import {CategoryType} from "../../../api/model/audience/category/CategoryType";
import {Currency} from "../../../api/model/currency/Currency";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {FormLayoutMessage} from "../../../common/component/snippet";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import useFormValidator from "../../../utils/hooks/useFormValidator";
import {SelectCategoryGroup} from "../../snippet";

type ModalCreateCategoryGroupProps = {
    active: boolean;
    onClose: () => void;
};

const BRAND_SAFETY_CATEGORY_GROUP_ID = 12863;

const ModalCreateCategoryGroup: FunctionComponent<ModalCreateCategoryGroupProps> = ({active, onClose}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCategories} = useTranslation(TranslationPortalFile.CATEGORIES);
    const alert = useAlert();
    const navigate = useNavigate();

    const FORM_ID = "form-create-category-group";
    const [categoryGroup, setCategoryGroup] = useState<CategoryGroup>(new CategoryGroup());
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [price, setPrice] = useState<number>();
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<string>();

    useEffect(() => {
        if (!active) return;

        (async () => {
            try {
                setCurrencies(await session.restCurrency.list());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("currencies", e.message);
                }
            }
        })();
    }, [alert, active]);

    useEffect(() => {
        if (active) {
            setCategoryGroup(new CategoryGroup());
            setSelectedTypes([]);
            setShowErrors(false);
        }
    }, [active, setShowErrors]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            "types": !selectedTypes.length,
            [CategoryField.PRICE]: !price || price <= 0
        }));
    }, [setErrors, selectedTypes, price]);

    const handleChange = (field: CategoryGroupField, value: any) => {
        setCategoryGroup((prevState) => new CategoryGroup({...prevState, [field]: value}));
    };

    const handleChangeTaxonomy = (taxonomyName: string) => {
        let parentId: number | undefined = undefined;
        switch (taxonomyName) {
            case CategoryTaxonomy.SIRDATA.name:
                setSelectedTypes([]);
                break;
            case CategoryTaxonomy.IAB.name:
                setSelectedTypes([CategoryType.CONTEXTUAL.name]);
                break;
            case CategoryTaxonomy.BRAND_SAFETY.name:
                setSelectedTypes([CategoryType.BRAND_SAFETY.name]);
                parentId = BRAND_SAFETY_CATEGORY_GROUP_ID;
                break;
        }
        handleChange(CategoryGroupField.TAXONOMY, taxonomyName);
        handleChange(CategoryGroupField.ID_PARENT, parentId);
    };

    const handleSelectType = (type: string) => {
        setSelectedTypes((prevState) => prevState.includes(type) ? prevState.filter((it) => it !== type) : [...prevState, type]);
    };

    const handleSubmitCapture = () => {
        setShowErrors(true);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setSubmitting(true);
        if (formValidator.hasErrors()) {
            return;
        }
        const rateUsd = currencies.find((it) => it.currency === Currency.USD.currency)?.exchange_rate || 1;
        const usdPrice = Formatter.roundNumber(price! * rateUsd, 5);
        const rateGbp = currencies.find((it) => it.currency === Currency.GBP.currency)?.exchange_rate || 1;
        const gbpPrice = Formatter.roundNumber(price! * rateGbp, 5);

        for (let type of selectedTypes) {
            categoryGroup.cats.push(new Category({
                [CategoryField.TYPE]: type,
                [CategoryField.PRICE]: price,
                [CategoryField.PRICE_USD]: Math.min(usdPrice, Category.MAX_PRICE_VALUE),
                [CategoryField.PRICE_GBP]: Math.min(gbpPrice, Category.MAX_PRICE_VALUE)
            }));
        }
        try {
            const newCategoryGroup = await session.restCategoryGroup.create(categoryGroup);
            navigate(newCategoryGroup.getRoute());
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToCreate("category group", e.message);
            }
        } finally {
            setShowErrors(true);
            setSubmitting(false);
        }
    };

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalHeader>
                <ModalHeaderTitle title={textCategories("modal.create.title")}/>
            </ModalHeader>
            <ModalContent>
                <Form id={FORM_ID} onSubmitCapture={handleSubmitCapture} onSubmit={handleSubmit} validationType={FormValidationType.CUSTOM}>
                    <FormLayoutRows>
                        <FormLayoutColumns columns={2}>
                            <FieldBlock label={textCategories(`field.${CategoryGroupField.TAXONOMY}`)} required>
                                <Select
                                    value={categoryGroup.taxonomy}
                                    options={CategoryTaxonomy.values().filter((it) => it.name !== CategoryTaxonomy.PARTNER.name).map((it) => ({value: it.name, label: textCategories(`taxonomy.${it.name}`)}))}
                                    onChange={(option) => handleChangeTaxonomy(`${option!.value}`)}
                                />
                            </FieldBlock>
                            <FieldBlock label={textCategories(`field.category.${CategoryField.PRICE}`)} required>
                                <InputTextNumber
                                    value={price ? Formatter.convertCentsToUnits(price) : undefined}
                                    min={0}
                                    max={Formatter.convertCentsToUnits(Category.MAX_PRICE_VALUE)}
                                    onChange={(value) => setPrice(Formatter.convertUnitsToCents(value))}
                                />
                                <span>{Currency.EUR.label}</span>
                            </FieldBlock>
                        </FormLayoutColumns>
                        <FieldBlock label={textCategories(`field.${CategoryGroupField.ID_PARENT}`)}>
                            <SelectCategoryGroup
                                value={categoryGroup.id_parent}
                                onChange={(parent) => handleChange(CategoryGroupField.ID_PARENT, parent?.id)}
                                taxonomy={CategoryTaxonomy.getByName(categoryGroup.taxonomy)}
                            />
                        </FieldBlock>
                        <FieldBlock label={textCategories(`field.${CategoryGroupField.NAME}`)} required>
                            <InputText
                                value={categoryGroup.name}
                                onChange={(value) => handleChange(CategoryGroupField.NAME, value)}
                            />
                        </FieldBlock>
                        {categoryGroup.taxonomy === CategoryTaxonomy.SIRDATA.name &&
                            <FieldBlock label={textCategories("field.types")} content={{direction: FlexContentDirection.COLUMN}} required>
                                <FlexContent direction={FlexContentDirection.ROW} spacing={FlexContentSpacing.XSMALL}>
                                    {CategoryType.values().filter((type) => type.name !== CategoryType.BRAND_SAFETY.name).map((type) =>
                                        <Checkbox
                                            key={type.name}
                                            name={type.name}
                                            checked={selectedTypes.includes(type.name)}
                                            onChange={() => handleSelectType(type.name)}
                                            label={textCategories(`category_type.${type.name}`)}
                                        />
                                    )}
                                </FlexContent>
                                {formValidator.isError("types") &&
                                    <FormLayoutMessage message={t("message.error.element_required", {element: "type"})} small severity={AlertSeverity.DANGER}/>
                                }
                            </FieldBlock>
                        }
                    </FormLayoutRows>
                </Form>
            </ModalContent>
            <ModalActions>
                <ButtonLinkCancel onClick={onClose}/>
                <ButtonValidate form={FORM_ID} loading={isSubmitting}/>
            </ModalActions>
        </ModalNew>
    );
};

export default ModalCreateCategoryGroup;
