export enum SalesRevenueField {
    ID = "id",
    SEAT_ID = "seat_id",
    DATE = "date",
    BILLING_MONTH = "billing_month",
    REVENUE = "revenue",
    IMPRESSION = "impression",
    CURRENCY = "currency",
    PRODUCT_ID = "product_id",
    BIDDER_PLATFORM_NAME = "bidder_platform_name",
    COST_AMOUNT = "cost_amount",
    COST_CURRENCY = "cost_currency",
    COST_TYPE = "cost_type",
    COST_ORGANIZATION_ID = "cost_organization_id",
    INVOICE_URLS = "invoice_urls",
    STATUS = "status",
    SEAT = "seat",
    PRODUCT = "product",
    COST_ORGANIZATION = "cost_organization"
}
