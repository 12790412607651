import {MomentInput} from "moment";
import {Formatter} from "../../../../../common/utils/Formatter";

export class SgtmContainerHitStatPeriodInterval {
    start: string = "";
    end: string = "";

    constructor(start?: MomentInput, end?: MomentInput) {
        this.start = Formatter.formatDate(start, Formatter.API_DATE_FORMAT);
        this.end = Formatter.formatDate(end, Formatter.API_DATE_FORMAT);
    }
}
