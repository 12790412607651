import {Action, Box, BoxProps, ContentBlock, FieldBlock, Form, FormLayoutColumns, FormLayoutRows, FormLayoutSeparator, FormLayoutTitle, FormValidationType, IconTooltip, InputDate, InputDateType, InputText, LayoutRows, Loadable, Select, SelectAutocomplete} from "@sirdata/ui-lib";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {PartnerOrganization} from "../../api/model/partner/organization/PartnerOrganization";
import {PartnerOrganizationField} from "../../api/model/partner/organization/PartnerOrganizationField";
import {RevenuePlatform} from "../../api/model/revenue/RevenuePlatform";
import {RevenueSeat} from "../../api/model/revenue/seat/RevenueSeat";
import {RevenueSeatField} from "../../api/model/revenue/seat/RevenueSeatField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {sortItems} from "../../common/utils/helper";
import {detectChanges} from "../../common/utils/portal";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {MainContentHeader, MainContentHeaderAction, SelectUser} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import {Status} from "../../utils/Status";
import {RevenueSeatSearchQuery} from "../../api/model/revenue/seat/RevenueSeatSearchQuery";

function SeatsDetails() {
    const {t: textOrganizations} = useTranslation(TranslationPortalFile.ORGANIZATIONS);
    const {t: textSeats} = useTranslation(TranslationPortalFile.SEATS);
    const {id} = useParams() as { id: string };
    const navigate = useNavigate();
    const alert = useAlert();

    const FORM_ID = "form-edit-seat";
    const [seat, setSeat] = useState<RevenueSeat>(new RevenueSeat());
    const [initSeat, setInitSeat] = useState(new RevenueSeat());
    const [isLoading, setLoading] = useState(true);
    const [isEditable, setEditable] = useState(false);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isShowModalDeleteSeat, setShowModalDeleteSeat] = useState(false);

    const [advertisers, setAdvertisers] = useState<string[]>([]);
    const [brands, setBrands] = useState<string[]>([]);
    const [clientSides, setClientSides] = useState<string[]>([]);
    const [countries, setCountries] = useState<string[]>([]);
    const [platforms, setPlatforms] = useState<RevenuePlatform[]>([]);

    const [organizations, setOrganizations] = useState<PartnerOrganization[]>([]);
    const [currentOrganizations, setCurrentOrganizations] = useState<PartnerOrganization[]>([]);

    useEffect(() => {
        (async () => {
            try {
                let seat = await session.restSeat.get(+id);

                let organizations = await session.restPartnerOrganization.list();
                organizations = sortItems(organizations, PartnerOrganizationField.NAME);
                setOrganizations(organizations);
                setCurrentOrganizations([...organizations]);

                seat.organization = organizations.find((it) => it.id === +seat.organization_id);
                setSeat(seat);
                setInitSeat(new RevenueSeat(seat));

                let platforms = await session.restRevenuesImport.getPlatforms();
                platforms = platforms.filter((platform) => !platform.isCurator()); // remove curator platforms
                setPlatforms(platforms);

                setClientSides(await session.restSeat.getClientSides());
                setCountries(await session.restSeat.getCountries());

                setAdvertisers(await session.restSeat.getAdvertisers());
                setBrands(await session.restSeat.getBrands());

                const account = await session.getAccount();
                const canValidate = account.hasAuthorization(Authorization.SEATS_VALIDATE.name);
                setEditable(canValidate || account.id === seat.owner_id);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("seat", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(seat, initSeat));
    }, [seat, initSeat]);

    useEffect(() => {
        handleChange(RevenueSeatField.ORGANIZATION, organizations.find((it) => it.id === seat.organization_id));
    }, [seat.organization_id, organizations]);

    const handleChange = (field: RevenueSeatField, value: any) => {
        setSeat((prevState) => new RevenueSeat({...prevState, [field]: value}));
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newSeat = await session.restSeat.update(seat);
            setSeat(newSeat);
            setInitSeat(new RevenueSeat(newSeat));
            alert.updateWithSuccess("seat");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                const newSearchQuery = new RevenueSeatSearchQuery();
                newSearchQuery.query = seat.seat_id;
                newSearchQuery.platform = seat.platform;
                const newSearchResult = await session.restSeat.search(newSearchQuery);
                const existingSeat = newSearchResult.seats.find((it) => it.seat_id === seat.seat_id);
                if (existingSeat) {
                    alert.failToUpdate("seat", textSeats("seat_already_exists", {
                        link: Module.SEATS.buildRoute(existingSeat.id),
                        seatId: existingSeat.id
                    }));
                } else {
                    alert.failToUpdate("seat", e.message);
                }
            }
        }
    };

    const handleValidate = async () => {
        try {
            await session.restSeat.validate(seat);
            alert.actionWithSuccess("seat validated");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failTo("validate seat", e.message);
            }
        }
    };

    const doDeleteSeat = async () => {
        try {
            await session.restSeat.delete(seat.id);
            navigate(Module.SEATS.path);
            alert.deleteWithSuccess("seat");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("seat", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.SEATS} element={initSeat.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.SEATS_VALIDATE.name}>
                    {seat.status === Status.PENDING.name && <MainContentHeaderAction action={Action.VALIDATE} onClick={handleValidate}/>}
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.SEATS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges || !isEditable}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setShowModalDeleteSeat(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <Loadable loading={isLoading}>
                            <ContentBlock>
                                <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <FormLayoutColumns>
                                        <FormLayoutRows>
                                            <FormLayoutTitle>{textSeats("modal.subtitle.organization")}</FormLayoutTitle>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock
                                                    label={textSeats(`field.${RevenueSeatField.ORGANIZATION}`)}
                                                    actions={
                                                        seat.organization ? (
                                                            <IconTooltip
                                                                icon={{
                                                                    ...Action.OPEN.icon,
                                                                    onClick: () => window.open(seat.organization?.getRoute())
                                                                }}
                                                                text={textSeats("actions.go_to_organization_page")}
                                                            />
                                                        ) : undefined
                                                    }
                                                    required
                                                >
                                                    <SelectAutocomplete
                                                        value={seat.organization_id}
                                                        options={currentOrganizations.map((it) => ({value: it.id, label: it.name}))}
                                                        onChange={(option) => handleChange(RevenueSeatField.ORGANIZATION_ID, option?.value || 0)}
                                                        clearable
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            {seat.organization &&
                                                <FormLayoutColumns>
                                                    {seat.organization.group &&
                                                        <FieldBlock
                                                            label={textSeats(`field.${RevenueSeatField.ORGANIZATION_GROUP}`)}
                                                            actions={
                                                                <IconTooltip
                                                                    icon={{
                                                                        ...Action.OPEN.icon,
                                                                        onClick: () => window.open(seat.organization?.group?.getRoute())
                                                                    }}
                                                                    text={textSeats("actions.go_to_organization_group_page")}
                                                                />
                                                            }
                                                        >
                                                            <InputText
                                                                value={seat.organization.group.name}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </FieldBlock>
                                                    }
                                                    {seat.organization.type &&
                                                        <FieldBlock label={textSeats(`field.${RevenueSeatField.ORGANIZATION_TYPE}`)}>
                                                            <InputText
                                                                value={textOrganizations(`type.${seat.organization.type}`)}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </FieldBlock>
                                                    }
                                                    {seat.organization.owner &&
                                                        <FieldBlock label={textSeats(`field.${RevenueSeatField.ORGANIZATION_OWNER}`)}>
                                                            <InputText
                                                                value={seat.organization.owner.name}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </FieldBlock>
                                                    }
                                                </FormLayoutColumns>
                                            }
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textSeats("modal.subtitle.platform")}</FormLayoutTitle>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.PLATFORM}`)} required>
                                                    <SelectAutocomplete
                                                        value={seat.platform}
                                                        options={platforms.map((it) => ({value: it.name, label: it.name}))}
                                                        onChange={(option) => handleChange(RevenueSeatField.PLATFORM, option?.value || "")}
                                                        clearable
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.CLIENT_SIDE}`)} required>
                                                    <Select
                                                        value={seat.client_side}
                                                        options={clientSides.map((it) => ({value: it, label: it}))}
                                                        onChange={(option) => handleChange(RevenueSeatField.CLIENT_SIDE, option?.value || "")}
                                                        clearable
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.SEAT_ID}`)}>
                                                    <InputText
                                                        value={seat.seat_id}
                                                        onChange={(value) => handleChange(RevenueSeatField.SEAT_ID, value.trim())}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.COUNTRY}`)}>
                                                    <SelectAutocomplete
                                                        value={seat.country}
                                                        options={countries.map((it) => ({value: it, label: it}))}
                                                        onChange={(option) => handleChange(RevenueSeatField.COUNTRY, option?.value || "")}
                                                        clearable
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        </FormLayoutRows>
                                        <FormLayoutRows>
                                            <FormLayoutTitle>{textSeats("modal.subtitle.advertiser")}</FormLayoutTitle>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.ADVERTISER}`)}>
                                                    <SelectAutocomplete
                                                        value={seat.advertiser}
                                                        options={advertisers.map((it) => ({label: it, value: it}))}
                                                        onChange={(option) => handleChange(RevenueSeatField.ADVERTISER, option?.value)}
                                                        clearable
                                                        creatable
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.ADVERTISER_ID}`)}>
                                                    <InputText
                                                        value={seat.advertiser_id}
                                                        onChange={(value) => handleChange(RevenueSeatField.ADVERTISER_ID, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.BRAND}`)}>
                                                    <SelectAutocomplete
                                                        value={seat.brand}
                                                        options={brands.map((it) => ({label: it, value: it}))}
                                                        onChange={(option) => handleChange(RevenueSeatField.BRAND, option?.value)}
                                                        clearable
                                                        creatable
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textSeats("modal.subtitle.sales")}</FormLayoutTitle>
                                            <FieldBlock label={textSeats(`field.${RevenueSeatField.OWNER_ID}`)} required>
                                                <SelectUser
                                                    value={seat.owner_id}
                                                    onChange={(user) => handleChange(RevenueSeatField.OWNER_ID, user?.id || 0)}
                                                    includeInactive
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns columns={2}>
                                                <FieldBlock label={textSeats(`field.${RevenueSeatField.END_DATE_NEW_BUSINESS}`)}>
                                                    <InputDate
                                                        type={InputDateType.DATE}
                                                        value={seat.end_date_new_business || ""}
                                                        onChange={(value) => handleChange(RevenueSeatField.END_DATE_NEW_BUSINESS, value || undefined)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        </FormLayoutRows>
                                    </FormLayoutColumns>
                                </Box>
                            </ContentBlock>
                        </Loadable>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isShowModalDeleteSeat}
                    entity="seat"
                    confirm={doDeleteSeat}
                    cancel={() => setShowModalDeleteSeat(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default SeatsDetails;
