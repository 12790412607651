import {Rest} from "../../common/api/rest/Rest";
import {SgtmContainerHitStat} from "../model/sgtm/container/stats/SgtmContainerHitStat";
import {SgtmContainerHitStatRequest} from "../model/sgtm/container/stats/SgtmContainerHitStatRequest";

export class RestSgtmContainerStat extends Rest {
    rootPath = "/console-api/sgtm/partner";

    getHitsStats(request: SgtmContainerHitStatRequest): Promise<SgtmContainerHitStat[]> {
        return this._client.get(`${this.rootPath}/${request.partner_id}/stats/hits`, SgtmContainerHitStat, {queryParams: request.getJsonParameters()}) as Promise<SgtmContainerHitStat[]>;
    }
}
