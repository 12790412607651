import React, {FunctionComponent} from "react";
import {ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle} from "@sirdata/ui-lib";
import {SalesRevenue} from "../../../../api/model/revenue/sales-revenue/SalesRevenue";
import {LabelStatus, LabelUser, Tag} from "../../index";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../../utils/constants";
import {Formatter} from "../../../../common/utils/Formatter";
import {Currency} from "../../../../api/model/currency/Currency";
import {SalesRevenueField} from "../../../../api/model/revenue/sales-revenue/SalesRevenueField";

type MonthlyRevenueRowProps = {
    revenue: SalesRevenue;
}

const MonthlyRevenueRow: FunctionComponent<MonthlyRevenueRowProps> = ({revenue}) => {
    const {t: textMonthlyRevenues} = useTranslation(TranslationPortalFile.MONTHLY_REVENUES);

    return (
        <TableRow onClick={{link: revenue.getRoute()}}>
            <TableColumn>
                <LabelStatus status={revenue.getStatus()}/>
            </TableColumn>
            <TableColumn>{Formatter.formatDate(revenue.date)}</TableColumn>
            <TableColumn>
                {revenue.seat.label()}
                {revenue.seat.isNewBusiness() &&
                    <Tag label={textMonthlyRevenues("status.new")} style={TagStyle.PRIMARY_RED}/>
                }
            </TableColumn>
            <TableColumn>
                <Tag label={revenue.seat.platform} style={TagStyle.PRIMARY_OCEAN}/>
            </TableColumn>
            <TableColumn>{revenue.product.label}</TableColumn>
            <TableColumn>{revenue.revenue ? Currency.formatAmount(Formatter.formatNumber(Formatter.convertCentsToUnits(revenue.revenue)), revenue.currency) : "-"}</TableColumn>
            <TableColumn>{Currency.formatAmount(Formatter.formatNumber(Formatter.convertCentsToUnits(revenue.cost_amount)), revenue.cost_currency)}</TableColumn>
            <TableColumn><LabelUser user={revenue.seat.owner}/></TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                {!!revenue.invoice_urls.length &&
                    <ActionsMenu
                        iconTooltip={{icon: {name: "receipt_long"}, text: textMonthlyRevenues(`field.${SalesRevenueField.INVOICE_URLS}`)}}
                        items={revenue.invoice_urls.map((url) => (
                            {
                                label: `${textMonthlyRevenues("actions.open_invoice")} "${url}"`,
                                onClick: () => window.open(`${url.startsWith("http") ? "" : "//"}${url}`, "_blank")
                            }
                        ))}
                    />
                }
            </TableActionColumn>
        </TableRow>
    );
};

export default MonthlyRevenueRow;
