import {useEffect, useState} from "react";
import {Box, BoxProps, BoxRadius, ContentBlock, InputDate, LayoutRows, Loadable, SearchField, SearchToolbar, Select} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {SgtmContainer} from "../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerHitStatPeriod} from "../../api/model/sgtm/container/stats/SgtmContainerHitStatPeriod";
import {SgtmContainerHitStatRequest} from "../../api/model/sgtm/container/stats/SgtmContainerHitStatRequest";
import {SgtmContainerHitStatRequestField} from "../../api/model/sgtm/container/stats/SgtmContainerHitStatRequestField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {ChartContainer, ChartLine, MainContentHeader} from "../../component/snippet";
import {Formatter} from "../../common/utils/Formatter";
import {TranslationPortalFile} from "../../utils/constants";
import {CHART_DATASET_DEFAULT_STYLES, GraphData} from "../../utils/cmp/CmpChartOptions";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";
import moment from "moment";

function SgtmContainersAnalytics() {
    const {t: textSgtmContainersAnalytics} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS_ANALYTICS);
    const {id} = useParams() as { id: string };
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [container, setContainer] = useState<SgtmContainer>(new SgtmContainer());
    const [request, setRequest] = useState<SgtmContainerHitStatRequest>(new SgtmContainerHitStatRequest().withPeriod(SgtmContainerHitStatPeriod.LAST_30_DAYS));
    const [hitGraphData, setHitGraphData] = useState<GraphData>();
    const [totalHits, setTotalHits] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                const container = await session.restSgtmContainer.get(+id);
                setContainer(container);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("container", e.message);
                    }
                }
            }
        })();
    }, [alert, id]);

    useEffect(() => {
        setRequest((prevState) => new SgtmContainerHitStatRequest({
            ...prevState,
            [SgtmContainerHitStatRequestField.PARTNER_ID]: container.partner_id,
            [SgtmContainerHitStatRequestField.CONFIG_ID]: container.id
        }));
    }, [container.id, container.partner_id]);

    useEffect(() => {
        if (request.isInvalid()) return;

        (async () => {
            try {
                setLoading(true);
                const hitStats = await session.restSgtmContainerStat.getHitsStats(request);

                setHitGraphData({
                    labels: hitStats.map((it) => it.date),
                    datasets: [{
                        label: textSgtmContainersAnalytics("label.hits"),
                        data: hitStats.map((it) => it.total_hits),
                        ...CHART_DATASET_DEFAULT_STYLES
                    }]
                });
                setTotalHits(hitStats.reduce((total, {total_hits}) => total + total_hits, 0));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("hit stats", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, request, textSgtmContainersAnalytics]);

    const handleChangeRequest = (field: SgtmContainerHitStatRequestField, value: any) => {
        setRequest((prevState) => new SgtmContainerHitStatRequest({...prevState, [field]: value}));
    };

    const handleChangePeriod = (period: SgtmContainerHitStatPeriod) => {
        setRequest((prevState) => new SgtmContainerHitStatRequest({...prevState}).withPeriod(period));
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.SGTM_CONTAINERS} element={container.toContentElement()}/>
            <MainContent>
                <LayoutRows>
                    <SearchToolbar>
                        <SearchField label={textSgtmContainersAnalytics(`label.${SgtmContainerHitStatRequestField.PERIOD}`)}>
                            <Select
                                value={request.period.name}
                                options={SgtmContainerHitStatPeriod.values().map((it) => ({value: it.name, label: textSgtmContainersAnalytics(`option.${it.name}`), period: it}))}
                                onChange={(option) => handleChangePeriod(option?.period)}
                            />
                        </SearchField>
                        {request.period.name === SgtmContainerHitStatPeriod.CUSTOM.name &&
                            <>
                                <SearchField label={textSgtmContainersAnalytics(`label.${SgtmContainerHitStatRequestField.START_DATE}`)}>
                                    <InputDate
                                        value={request.start_date}
                                        onChange={(value) => handleChangeRequest(SgtmContainerHitStatRequestField.START_DATE, value)}
                                        max={Formatter.formatDate(moment(request.end_date).subtract(1, "day"), Formatter.API_DATE_FORMAT)}
                                    />
                                </SearchField>
                                <SearchField label={textSgtmContainersAnalytics(`label.${SgtmContainerHitStatRequestField.END_DATE}`)}>
                                    <InputDate
                                        value={request.end_date}
                                        onChange={(value) => handleChangeRequest(SgtmContainerHitStatRequestField.END_DATE, value)}
                                        min={Formatter.formatDate(moment(request.start_date).add(1, "day"), Formatter.API_DATE_FORMAT)}
                                        max={Formatter.formatDate(moment(), Formatter.API_DATE_FORMAT)}
                                    />
                                </SearchField>
                            </>
                        }
                    </SearchToolbar>
                    <Loadable loading={isLoading}>
                        <ContentBlock header={{title: {label: textSgtmContainersAnalytics("evolution_by_hits")}}} cssClass="analytics__hits">
                            <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD}>
                                <div className="analytics__hits__metric">
                                    <span className="analytics__hits__metric__header">{textSgtmContainersAnalytics("hits_total")}</span>
                                    <span className="analytics__hits__metric__value">{Formatter.formatNumber(totalHits)}</span>
                                </div>
                                <ChartContainer height="35rem">
                                    <ChartLine data={hitGraphData}/>
                                </ChartContainer>
                            </Box>
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default SgtmContainersAnalytics;
