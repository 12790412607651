import {ApiModel} from "../../../common/api/model/ApiModel";
import {ApiService} from "../ApiService";

export class PartnerService extends ApiModel {
    is_live: boolean = false;
    total_hits: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static services(): ApiService[] {
        return [
            ApiService.AUDIENCE,
            ApiService.CMP,
            ApiService.CUSTOMER,
            ApiService.GTM_SERVER_SIDE,
            ApiService.HELPER
        ];
    };
}
