import {ChartOptions} from "chart.js";
import numeral from "numeral";
import {Formatter} from "../../common/utils/Formatter";
import {CmpStatsColor} from "./CmpStatsColor";

export type GraphDataset = {
    label?: string;
    borderWidth?: number;
    borderColor?: string;
    backgroundColor: string | string[];
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointRadius?: number;
    data: number[];
    fill?: string | number;
};

export type GraphData = {
    labels: string[];
    datasets: GraphDataset[];
};

export const chartLineOptions: ChartOptions<"line"> = {
    animation: false,
    maintainAspectRatio: false,
    elements: {
        line: {
            tension: 0.4
        },
        point: {
            pointStyle: "rectRounded",
            hoverRadius: 10
        }
    },
    scales: {
        x: {
            type: "time",
            time: {
                unit: "day",
                displayFormats: {
                    day: "DD/MM"
                }
            },
            grid: {
                display: false
            },
            border: {
                display: false
            }
        },
        y: {
            stacked: true,
            ticks: {
                callback: (tickValue) => Formatter.formatNumber(tickValue as number)
            },
            beginAtZero: true
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            mode: "index",
            position: "nearest",
            displayColors: true,
            callbacks: {
                title: (tooltipItem) => Formatter.formatDate(tooltipItem[0].label, "DD/MM/YYYY"),
                label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${numeral(tooltipItem.formattedValue).format("0,0").replaceAll(",", " ")}`
            }
        }
    }
};

export const optionsRating: ChartOptions<"line"> = {
    ...chartLineOptions,
    scales: {
        x: chartLineOptions.scales!.x,
        y: {
            ...chartLineOptions.scales!.y,
            offset: true,
            max: 100
        }
    },
    plugins: {
        ...chartLineOptions.plugins,
        tooltip: {
            mode: "index",
            callbacks: {
                title: (tooltipItem) => Formatter.formatDate(tooltipItem[0].label, Formatter.DATE_FORMAT),
                label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}%`
            }
        }
    }
};

export const ratingOptionsUnstacked: ChartOptions<"line"> = {
    ...optionsRating,
    scales: {
        x: chartLineOptions.scales!.x,
        y: {
            ...optionsRating.scales!.y,
            offset: false,
            stacked: false,
            max: 100,
            min: 0
        }
    }
};

export const CHART_DATASET_DEFAULT_STYLES = {
    borderWidth: 2,
    borderColor: CmpStatsColor.HITS+"85",
    backgroundColor: [CmpStatsColor.HITS+"40"],
    pointBackgroundColor: CmpStatsColor.HITS,
    pointBorderColor: CmpStatsColor.HITS,
    pointRadius: 4,
    fill: "origin"
};

export const CHART_DATASET_CONSENT_STYLES = {
    ...CHART_DATASET_DEFAULT_STYLES,
    borderColor: CmpStatsColor.CONSENT+"85",
    backgroundColor: [CmpStatsColor.CONSENT+"40"],
    pointBackgroundColor: CmpStatsColor.CONSENT,
    pointBorderColor: CmpStatsColor.CONSENT
};

export const CHART_DATASET_OBJECT_STYLES = {
    ...CHART_DATASET_DEFAULT_STYLES,
    borderColor: CmpStatsColor.OBJECT+"85",
    backgroundColor: [CmpStatsColor.OBJECT+"40"],
    pointBackgroundColor: CmpStatsColor.OBJECT,
    pointBorderColor: CmpStatsColor.OBJECT,
    fill: 0
};

export const CHART_DATASET_NO_CHOICE_STYLES = {
    ...CHART_DATASET_DEFAULT_STYLES,
    borderColor: CmpStatsColor.NO_CHOICE+"85",
    backgroundColor: [CmpStatsColor.NO_CHOICE+"40"],
    pointBackgroundColor: CmpStatsColor.NO_CHOICE,
    pointBorderColor: CmpStatsColor.NO_CHOICE,
    fill: 1
};
