import React, {FC, useEffect, useState} from "react";
import {Action, ActionsMenu, ContentBlock, IconTooltip, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {CmpConfigField} from "../../../api/model/cmp/config/CmpConfigField";
import {CmpConfigVersion} from "../../../api/model/cmp/config/CmpConfigVersion";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {CmpPreviewHelper} from "../../../utils/cmp/CmpPreviewHelper";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {LabelStatus} from "../index";

type PartnerCmpConfigurationsProps = {
    partnerId: number;
};

const PartnerCmpConfigurations: FC<PartnerCmpConfigurationsProps> = ({partnerId}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCmpConfigurations} = useTranslation(TranslationPortalFile.CMP_CONFIGURATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [cmpConfigs, setCmpConfigs] = useState<CmpConfig[]>([]);

    useEffect(() => {
        if (!partnerId) return;
        (async () => {
            try {
                const configs = await session.restCmpConfiguration.list(partnerId);
                setCmpConfigs(configs);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("CMP configurations", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, partnerId]);

    return (
        <Loadable loading={isLoading}>
            <ContentBlock>
                <Table
                    columns={[
                        {width: 5, label: textCmpConfigurations(`field.${CmpConfigField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textCmpConfigurations(`field.${CmpConfigField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 40, label: textCmpConfigurations(`field.${CmpConfigField.NAME}`)},
                        {width: 40, label: textCmpConfigurations(`field.${CmpConfigField.DOMAIN}`)},
                        {width: 5}
                    ]}
                    messageIfEmpty={textCmpConfigurations("message.no_cmp_config_for_partner")}
                >
                    {cmpConfigs.map((config) =>
                        <TableRow key={config.id}>
                            <TableColumn>
                                <LabelStatus status={config.getStatus()}/>
                            </TableColumn>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{config.id}</TableColumn>
                            <TableColumn>{config.name}</TableColumn>
                            <TableColumn>{config.domain}</TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <ActionsMenu
                                    iconTooltip={{
                                        icon: {name: "visibility"},
                                        text: textCmpConfigurations("actions.preview")
                                    }}
                                    items={[
                                        {label: `${textCmpConfigurations("current_version")} (${config.getConfigVersion().label})`, onClick: () => CmpPreviewHelper.openPreview(config)},
                                        ...CmpConfigVersion.values().map((item, index) =>
                                            ({label: item.gdprUIVersion.label, onClick: () => CmpPreviewHelper.openPreview(config, item), separator: index === 0})
                                        ),
                                        ...CmpConfigVersion.values().map((item) =>
                                            ({label: item.ccpaUIVersion.label, onClick: () => CmpPreviewHelper.openPreview(config, item, true)})
                                        )
                                    ]}
                                />
                                <RestrictedContent allowedTo={Authorization.CMP_CONFIGURATIONS.name}>
                                    <IconTooltip
                                        icon={Action.OPEN.icon}
                                        text={textCommon(Action.OPEN.labelKey)}
                                        onClick={() => window.open(config.getRoute(), "_blank")}
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>

            </ContentBlock>
        </Loadable>
    );
};

export default PartnerCmpConfigurations;
