export enum CmpStatsColor {
    CONSENT = "#16D2A5",
    OBJECT = "#F67262",
    NO_CHOICE = "#E9ECEF",
    CLOSE = "#FBCA45",
    ASK_LATER = "#5BC0DE",
    CONTINUE = "#F5A131",
    SAVE = "#505050",
    PURPOSES = "#A65DEE",
    SEE_PURPOSES = "#D2ADF6",
    PARTNERS = "#F99AD6",
    SEE_PARTNERS = "#FBCCEA",
    SITES = "#3333CC",
    HITS = "#16D2A5"
}
