import {Color, InputDate, LayoutRows, SearchField, SearchToolbar, Select, Tabs} from "@sirdata/ui-lib";
import moment from "moment";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {CmpConfig} from "../../api/model/cmp/config/CmpConfig";
import {CmpStatPeriod} from "../../api/model/cmp/stat/CmpStatPeriod";
import {CmpStatRequest} from "../../api/model/cmp/stat/CmpStatRequest";
import {CmpStatRequestField} from "../../api/model/cmp/stat/CmpStatRequestField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {CmpConfigurationAnalyticsConsent, CmpConfigurationAnalyticsHit, MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";

function CmpConfigurationsAnalytics() {
    const {t: textCmpAnalytics} = useTranslation(TranslationPortalFile.CMP_ANALYTICS);
    const alert = useAlert();
    const {id} = useParams() as { id: string };
    const [request, setRequest] = useState<CmpStatRequest>(new CmpStatRequest().withPeriod(CmpStatPeriod.LAST_7_DAYS));
    const [config, setConfig] = useState<CmpConfig>();

    useEffect(() => {
        (async () => {
            try {
                const config = await session.restCmpConfiguration.get(id);
                setConfig(config);
                setRequest((prevState) => new CmpStatRequest({
                    ...prevState,
                    [CmpStatRequestField.CONFIG_ID]: config.id,
                    [CmpStatRequestField.PARTNER_ID]: config.id_partner
                }));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("configuration", e.message);
                    }
                }
            }
        })();
    }, [id, alert]);

    const handleChangeRequest = (field: CmpStatRequestField, value: any) => {
        setRequest((prevState) => new CmpStatRequest({...prevState, [field]: value}));
    };

    const handleChangePeriod = (period: CmpStatPeriod) => {
        setRequest((prevState) => new CmpStatRequest({...prevState}).withPeriod(period));
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.CMP_CONFIGURATIONS} element={config?.toContentElement()}/>
            <MainContent>
                <LayoutRows>
                    <SearchToolbar>
                        <SearchField label={textCmpAnalytics(`label.${CmpStatRequestField.PERIOD}`)}>
                            <Select
                                value={request.period}
                                options={CmpStatPeriod.values().map((it) => ({value: it.name, label: textCmpAnalytics(`option.${it.name}`), period: it}))}
                                onChange={(option) => handleChangePeriod(option?.period)}
                            />
                        </SearchField>
                        {request.period === CmpStatPeriod.CUSTOM.name &&
                            <>
                                <SearchField label={textCmpAnalytics(`label.${CmpStatRequestField.START}`)}>
                                    <InputDate
                                        value={request.start}
                                        onChange={(value) => handleChangeRequest(CmpStatRequestField.START, value)}
                                        max={request.end}
                                    />
                                </SearchField>
                                <SearchField label={textCmpAnalytics(`label.${CmpStatRequestField.END}`)}>
                                    <InputDate
                                        value={request.end}
                                        onChange={(value) => handleChangeRequest(CmpStatRequestField.END, value)}
                                        min={request.start}
                                        max={Formatter.formatDate(moment().subtract(1, "day"), Formatter.API_DATE_FORMAT)}
                                    />
                                </SearchField>
                            </>
                        }
                    </SearchToolbar>
                    <Tabs
                        headers={[
                            {label: textCmpAnalytics("consent.title"), icon: {name: "stacked_line_chart", colorIcon: Color.CYAN}},
                            {label: textCmpAnalytics("hits.title"), icon: {name: "monitoring", colorIcon: Color.CYAN}}
                        ]}
                    >
                        <CmpConfigurationAnalyticsConsent statRequest={request}/>
                        <CmpConfigurationAnalyticsHit statRequest={request}/>
                    </Tabs>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default CmpConfigurationsAnalytics;
