import {Action, ContentBlock, IconTooltip, LayoutRows, Loadable, SearchError, SearchToolbar, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {MainHeader} from "../../common/component/snippet";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {ApiDocumentationItem} from "../../api/model/api-documentation/ApiDocumentationItem";
import {ApiDocumentationItemField} from "../../api/model/api-documentation/ApiDocumentationItemField";

function ApiDocumentations() {
    const {t: textApiDocs} = useTranslation(TranslationPortalFile.API_DOCUMENTATIONS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [docItems, setDocItems] = useState<ApiDocumentationItem[]>([]);
    const {buildSearchResult, ...search} = useSearch(ApiDocumentationItem);

    useEffect(() => {
        (async () => {
            try {
                const items = await session.restDocs.list();
                setDocItems(items);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("docs", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert]);

    useEffect(() => {
        let currentItems = (docItems || []);
        if (search.searchQuery.query) {
            currentItems = currentItems.filter((item) => item.name.toLowerCase().includes(search.searchQuery.query.toLowerCase()));
        }
        buildSearchResult(currentItems);
    }, [docItems, search.searchQuery, buildSearchResult]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.API_DOCUMENTATIONS}/>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar searchBar={{placeholder: textApiDocs("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}/>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 20, label: textApiDocs(`field.${ApiDocumentationItemField.NAME}`)},
                                        {width: 70, label: textApiDocs(`field.${ApiDocumentationItemField.DESCRIPTION}`)},
                                        {width: 5, label: textApiDocs(`field.${ApiDocumentationItemField.PUBLIC_PATH}`), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textApiDocs(`field.${ApiDocumentationItemField.PRIVATE_PATH}`), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((item) =>
                                        <TableRow key={item.name}>
                                            <TableColumn>{item.name}</TableColumn>
                                            <TableColumn>{item.description}</TableColumn>
                                            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                                {item.public_path &&
                                                    <IconTooltip
                                                        icon={Action.OPEN.icon}
                                                        text={textApiDocs("actions.open_doc")}
                                                        onClick={() => window.open(item.public_path, "_blank")}
                                                    />
                                                }
                                            </TableActionColumn>
                                            <TableActionColumn styles={TableColumnStyle.ALIGN_CENTER}>
                                                {item.private_path &&
                                                    <IconTooltip
                                                        icon={Action.OPEN.icon}
                                                        text={textApiDocs("actions.open_doc")}
                                                        onClick={() => window.open(item.private_path, "_blank")}
                                                    />
                                                }
                                            </TableActionColumn>
                                        </TableRow>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default ApiDocumentations;
