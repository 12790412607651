import {Box, BoxProps, BoxRadius} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {CmpConfigurationAnalyticsTrend} from "../index";

type CmpConfigurationAnalyticsMetricCardProps = {
    header: {
        label: string;
        image?: string;
    };
    value: string;
    trendValue: number;
    showTrend: boolean;
};

const CmpConfigurationAnalyticsMetricCard: FunctionComponent<CmpConfigurationAnalyticsMetricCardProps> = ({header, value, trendValue, showTrend}) => {
    return (
        <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD} cssClass="metric-card">
            <div className="metric-card__header">
                {header.label}
                {header.image && <img src={header.image} alt=""/>}
            </div>
            <span className="metric-card__value">{value}</span>
            {showTrend && <CmpConfigurationAnalyticsTrend value={trendValue}/>}
        </Box>
    );
};

export default CmpConfigurationAnalyticsMetricCard;
