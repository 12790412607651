import {ApiModel} from "../../../common/api/model/ApiModel";

export class ApiDocumentationItem extends ApiModel {
    name: string = "";
    description: string = "";
    public_path?: string;
    private_path?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
