import {Color} from "@sirdata/ui-lib";
import clsx from "clsx";
import {ApiModel} from "../../../common/api/model/ApiModel";
import {CountryCode} from "../../../common/api/model/Country";
import {CountryIsoCode} from "../../../common/api/model/CountryIsoCode";
import {Origin} from "../../../common/api/model/Origin";
import {abbreviate} from "../../../common/utils/string";
import {pathPartnersConnect} from "../../../utils/constants";
import {Module} from "../../../utils/Module";
import {Status} from "../../../utils/Status";
import {ContentElement} from "../../interface/ContentElement";
import {ApiService} from "../ApiService";
import {BanProperty} from "./BanProperty";
import {PartnerAccount} from "./PartnerAccount";
import {PartnerAccountType} from "./PartnerAccountType";
import {PartnerConsentStatus} from "./PartnerConsentStatus";
import {PartnerField} from "./PartnerField";
import {PartnerProperties} from "./PartnerProperties";
import {PartnerService} from "./PartnerService";
import {PARTNER_TYPES, PartnerType} from "./PartnerType";

export class Partner extends ApiModel {
    id: number = 0;
    email: string = "";
    first_name: string = "";
    last_name: string = "";
    company: string = "";
    active: boolean = false;
    account_active: boolean = false;
    owner_account_id: number = 0;
    currency_id: number = 1;
    country_id: number = CountryCode.FRANCE.id;
    country: CountryIsoCode = CountryCode.FRANCE.code;
    user: string = "";
    type: PartnerType = PARTNER_TYPES[0];
    branded: boolean = false;
    data: boolean = true;
    categorize_request: boolean = true;
    max_sync_per_request: number = 50;
    is_merchant: boolean = false;
    is_brazil_merchant: boolean = false;
    ban_property: BanProperty = new BanProperty();
    axciom_id: string = "";
    consent_status: string = PartnerConsentStatus.NON_COMPLIANT;
    privacy_policy_url: string = "";
    forbid_user_creation: boolean = false;
    allows_sirdata_access: boolean = false;
    creation_service: string = "";
    properties: PartnerProperties = new PartnerProperties();
    readonly organization_id?: number;
    readonly created_at: string = "";
    readonly updated_at: string = "";
    private _services: Map<string, PartnerService> = new Map<string, PartnerService>();
    private _accounts: PartnerAccount[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObject(PartnerField.BAN_PROPERTY, BanProperty);
        this.loadObject(PartnerField.PROPERTIES, PartnerProperties);
    }

    get fullName(): string {
        return clsx(this.first_name, this.last_name) || this.company;
    }

    get nameWithCompany(): string {
        let company = abbreviate(this.company || "[UNKNOWN]", 30);
        if (this.fullName && this.fullName !== this.company) {
            return `${company} (${abbreviate(this.fullName, 30)})`;
        }
        return company;
    }

    get user_name(): string {
        return this.user.substr(0, 3).toUpperCase();
    }

    get services(): Map<string, PartnerService> {
        return this._services;
    }

    set services(services: Map<string, PartnerService>) {
        const map = new Map<string, PartnerService>();
        const entries = Object.entries(services);
        if (entries.length) {
            entries.forEach(([apiService, data]) => map.set(apiService, new PartnerService(data)));
        }
        this._services = map;
    }

    get accounts(): PartnerAccount[] {
        return this._accounts;
    }

    set accounts(list: PartnerAccount[]) {
        let accounts = [];
        for (let a of list) {
            accounts.push(new PartnerAccount(a));
        }
        this._accounts = accounts;
    }

    get owner(): PartnerAccount {
        if (!this._accounts.length) {
            this._accounts.push(new PartnerAccount());
        }
        return this._accounts.find((it) => it.type === PartnerAccountType.OWNER) || new PartnerAccount();
    }

    set owner(account: PartnerAccount) {
        if (!this._accounts.length) {
            this._accounts.push(account);
            return;
        }
        const ownerAccount = this._accounts.find((it) => it.type === PartnerAccountType.OWNER);
        if (ownerAccount) {
            ownerAccount.load(account);
        }
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            company: this.company,
            active: this.active,
            currency_id: this.currency_id,
            country_id: this.country_id,
            user: this.user,
            type: this.type,
            branded: this.branded,
            data: this.data,
            categorize_request: this.categorize_request,
            max_sync_per_request: this.max_sync_per_request,
            is_merchant: this.is_merchant,
            is_brazil_merchant: this.is_brazil_merchant,
            ban_property: this.ban_property,
            axciom_id: this.axciom_id,
            consent_status: this.consent_status,
            privacy_policy_url: this.privacy_policy_url,
            forbid_user_creation: this.forbid_user_creation,
            properties: this.properties.getJsonParameters(),
            accounts: this._accounts
        };
    }

    getRoute(): string {
        return Module.PARTNERS.buildRoute(this.id);
    }

    getPartnerAreaRoute(origin: Origin): string {
        return `${window.location.origin + pathPartnersConnect}?origin=${origin.name}&partner_id=${this.id}&partner_account_id=${this.owner_account_id}`;
    }

    getStatus(): Status {
        return this.active ? Status.ACTIVE : Status.INACTIVE;
    }

    getServiceStatusColor(service: ApiService): Color {
        const partnerService = this.services.get(service.name);
        switch (true) {
            case !partnerService:
                return Color.GREY;
            case [ApiService.CMP, ApiService.GTM_SERVER_SIDE, ApiService.HELPER].includes(service):
                return partnerService!.is_live && partnerService!.total_hits > 0 ? Color.GREEN : Color.RED;
            default:
                return Color.MIDNIGHT_LIGHT;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.fullName);
        element.status = this.getStatus();
        element.createdAt = new Date(this.created_at);
        element.lastUpdated = new Date(this.updated_at);
        return element;
    }
}
