import {Color, Tag, TagStyle, Tooltip} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

type CmpConfigurationAnalyticsTrendProps = {
    value: number;
};

const CmpConfigurationAnalyticsTrend: FunctionComponent<CmpConfigurationAnalyticsTrendProps> = ({value}) => {
    const {t: textCmpAnalytics} = useTranslation(TranslationPortalFile.CMP_ANALYTICS);

    if (value === 0 || value === Infinity) {
        return <></>;
    }

    return (
        <div className="trend">
            <Tooltip text={textCmpAnalytics("evolution_on_period")}>
                {value > 0 ?
                    <Tag
                        icon={{name: "arrow_upward"}}
                        style={TagStyle.DEFAULT_CYAN_LIGHT}
                        label={value + "%"}
                    /> :
                    <Tag
                        icon={{name: "arrow_downward"}}
                        customColor={{color: Color.CORAL, borderColor: Color.CORAL, backgroundColor: Color.CORAL_LIGHTER}}
                        label={value + "%"}
                    />
                }
            </Tooltip>
        </div>
    );
};

export default CmpConfigurationAnalyticsTrend;
