import {Box, BoxProps, BoxRadius, ContentBlock, Loadable} from "@sirdata/ui-lib";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CmpHitStat} from "../../../api/model/cmp/stat/CmpHitStat";
import {CmpStatRequest} from "../../../api/model/cmp/stat/CmpStatRequest";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {Formatter} from "../../../common/utils/Formatter";
import {CHART_DATASET_DEFAULT_STYLES, GraphData} from "../../../utils/cmp/CmpChartOptions";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {ChartContainer, ChartLine} from "../index";

type CmpConfigurationAnalyticsHitProps = {
    statRequest: CmpStatRequest;
};

const CmpConfigurationAnalyticsHit: FC<CmpConfigurationAnalyticsHitProps> = ({statRequest}) => {
    const {t: textCmpAnalytics} = useTranslation(TranslationPortalFile.CMP_ANALYTICS);
    const alert = useAlert();
    const [hitStats, setHitStats] = useState<CmpHitStat[]>([]);
    const [totalHits, setTotalHits] = useState(0);
    const [hitGraphData, setHitGraphData] = useState<GraphData>();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (statRequest.isInvalid()) {
            return;
        }

        (async () => {
            try {
                setLoading(true);
                setHitStats(await session.restCmpPartner.getHitsStats(statRequest));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("hit stats", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, statRequest]);

    useEffect(() => {
        setHitGraphData({
            labels: hitStats.map((it) => it.date),
            datasets: [{
                label: textCmpAnalytics("label.hits"),
                data: hitStats.map((it) => it.hits),
                ...CHART_DATASET_DEFAULT_STYLES
            }]
        });
        setTotalHits(hitStats.reduce((total, {hits}) => total + hits, 0));
    }, [textCmpAnalytics, hitStats]);

    return (
        <Loadable loading={isLoading}>
            <ContentBlock header={{title: {label: textCmpAnalytics("hits.evolution_by_hits")}}} cssClass="analytics__hits">
                <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD}>
                    <div className="analytics__hits__metric">
                        <span className="analytics__hits__metric__header">{textCmpAnalytics("metric.hits_total")}</span>
                        <span className="analytics__hits__metric__value">{Formatter.formatNumber(totalHits)}</span>
                    </div>
                    <ChartContainer height="35rem">
                        <ChartLine data={hitGraphData}/>
                    </ChartContainer>
                </Box>
            </ContentBlock>
        </Loadable>
    );
};

export default CmpConfigurationAnalyticsHit;
