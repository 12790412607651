import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {SgtmContainer} from "../model/sgtm/container/SgtmContainer";
import {SgtmContainerSearchQuery} from "../model/sgtm/container/SgtmContainerSearchQuery";
import {SgtmContainerSearchResult} from "../model/sgtm/container/SgtmContainerSearchResult";

export class RestSgtmContainer extends Rest implements RestService<SgtmContainer> {
    rootPath = "/console-api/sgtm/container";

    search(params: SgtmContainerSearchQuery): Promise<SgtmContainerSearchResult> {
        return this._client.get(`${this.rootPath}`, SgtmContainerSearchResult, {queryParams: params.getJsonParameters()}) as Promise<SgtmContainerSearchResult>;
    }

    list(partnerId: number): Promise<SgtmContainer[]> {
        return this._client.get(`${this.rootPath}/list`, SgtmContainer, {queryParams: {partner_id: partnerId}}) as Promise<SgtmContainer[]>;
    }

    create(container: SgtmContainer): Promise<SgtmContainer> {
        return this._client.post(`${this.rootPath}`, container, SgtmContainer) as Promise<SgtmContainer>;
    }

    get(containerId: number): Promise<SgtmContainer> {
        return this._client.get(`${this.rootPath}/${containerId}`, SgtmContainer) as Promise<SgtmContainer>;
    }

    update(container: SgtmContainer): Promise<SgtmContainer> {
        return this._client.put(`${this.rootPath}/${container.id}`, container, SgtmContainer) as Promise<SgtmContainer>;
    }

    delete(containerId: number) {
        return this._client.delete(`${this.rootPath}/${containerId}`);
    }
}
