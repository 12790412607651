import {Select} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Status} from "../../../utils/Status";

type SelectStatusProps = {
    value: string | undefined;
    statuses: Status[];
    onChange: ((status?: Status) => void) | undefined;
    onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
    onInvalid?: (e: React.FormEvent<HTMLInputElement>) => void;
    small?: boolean;
    clearable?: boolean;
    disabled?: boolean;
};

const SelectStatus: FunctionComponent<SelectStatusProps> = ({value, statuses, onChange, ...rest}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <Select
            value={value}
            options={statuses.map((it) => ({value: it.name, label: t(`status.${it.name}`), status: it}))}
            onChange={(option) => onChange && onChange(option?.status)}
            {...rest}
        />
    );
};

export default SelectStatus;
