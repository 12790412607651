import {
    Box,
    BoxProps,
    BoxRadius,
    Checkbox,
    Color,
    ContentBlock,
    FieldBlock,
    FlexContentDirection,
    FlexContentSpacing,
    FormLayoutRows,
    FormLayoutSeparator,
    FormRowTitle,
    InputText,
    LayoutColumns,
    SelectAutocomplete,
    TagStyle,
    ToggleSwitch
} from "@sirdata/ui-lib";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CmpConfig} from "../../../api/model/cmp/config/CmpConfig";
import {HelperDomain} from "../../../api/model/helper/domain/HelperDomain";
import {HelperDomainSearchQuery} from "../../../api/model/helper/domain/HelperDomainSearchQuery";
import {SgtmContainer} from "../../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerField} from "../../../api/model/sgtm/container/SgtmContainerField";
import {DEFAULT_GTM_DATALAYER} from "../../../api/model/sgtm/container/SgtmContainerProperties";
import {SgtmContainerPropertiesField} from "../../../api/model/sgtm/container/SgtmContainerPropertiesField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {Tag} from "../index";

type SgtmContainerAdvancedConfigurationProps = {
    container: SgtmContainer;
    onChange: (fields: { [key in SgtmContainerPropertiesField]?: any }) => void;
};

const SgtmContainerAdvancedConfiguration: FC<SgtmContainerAdvancedConfigurationProps> = ({container, onChange}) => {
    const alert = useAlert();
    const {t: textSgtmContainers} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS);
    const [cmpConfigList, setCmpConfigList] = useState<CmpConfig[]>([]);
    const [helperDomainList, setHelperDomainList] = useState<HelperDomain[]>([]);
    const [isUseDataLayerCustom, setUseDataLayerCustom] = useState<boolean>(!!container.default_properties.gtm_datalayer);

    useEffect(() => {
        (async () => {
            try {
                const configs = await session.restCmpConfiguration.list(container.partner_id);
                setCmpConfigList(configs);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner's CMPs", e.message);
                }
            }
            try {
                const helperDomainSearchQuery = new HelperDomainSearchQuery();
                helperDomainSearchQuery.partner_id = container.partner_id;
                const helperDomains = await session.restHelperDomain.search(helperDomainSearchQuery);
                setHelperDomainList(helperDomains.elements);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partner's Helper domains", e.message);
                }
            }
        })();
    }, [alert, container.partner_id]);

    const handleChangeProperties = (field: SgtmContainerPropertiesField, checked: boolean) => {
        switch (field) {
            case SgtmContainerPropertiesField.GTM_PATH_ACTIVE:
                onChange({
                    [SgtmContainerPropertiesField.GTM_PATH_ACTIVE]: checked,
                    [SgtmContainerPropertiesField.GTM_PATH]: container.default_properties.gtm_path || `${SgtmContainer.getRandomPath()}.js`
                });
                return;
            case SgtmContainerPropertiesField.GA4_PATH_ACTIVE:
                onChange({
                    [SgtmContainerPropertiesField.GA4_PATH_ACTIVE]: checked,
                    [SgtmContainerPropertiesField.GA4_PATH]: container.default_properties.ga4_path || `${SgtmContainer.getRandomPath()}.js`,
                    [SgtmContainerPropertiesField.GA4_COLLECT_PATH]: container.default_properties.ga4_collect_path || SgtmContainer.getRandomPath()
                });
                return;
            case SgtmContainerPropertiesField.FIRSTID_ACTIVE:
                if (!checked) {
                    onChange({
                        [SgtmContainerPropertiesField.FIRSTID_ACTIVE]: checked,
                        [SgtmContainerPropertiesField.FIRSTID_AUTOLOAD]: checked
                    });
                    return;
                }
                onChange({[SgtmContainerPropertiesField.FIRSTID_ACTIVE]: checked});
                return;
            case SgtmContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE:
                if (!checked) {
                    onChange({
                        [SgtmContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE]: checked,
                        [SgtmContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE]: checked,
                        [SgtmContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE]: checked
                    });
                    return;
                }
                onChange({[SgtmContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE]: checked});
                return;
            default:
                onChange({[field]: checked});
                return;
        }
    };

    const handleChangeDataLayerCustom = (checked: boolean) => {
        setUseDataLayerCustom(checked);
        if (!checked && container.default_properties.gtm_datalayer) {
            onChange({[SgtmContainerPropertiesField.GTM_DATALAYER]: ""});
        }
    };

    return (
        <ContentBlock header={{title: {label: textSgtmContainers("section.advanced_configuration.")}}}>
            <FormLayoutRows>
                <ToggleSwitch
                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.PROCESSING_OVERLAY}`)}
                    name={SgtmContainerPropertiesField.PROCESSING_OVERLAY}
                    checked={container.default_properties.processing_overlay}
                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.PROCESSING_OVERLAY, checked)}
                />
                <LayoutColumns>
                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                            <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                <FormRowTitle>
                                    <Tag label={textSgtmContainers("section.advanced_configuration.ad_blockers")} style={TagStyle.DEFAULT_OCEAN}/>
                                </FormRowTitle>
                                <FieldBlock>
                                    <ToggleSwitch
                                        label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.GTM_PATH_ACTIVE}`)}
                                        name={SgtmContainerPropertiesField.GTM_PATH_ACTIVE}
                                        checked={container.default_properties.gtm_path_active}
                                        onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.GTM_PATH_ACTIVE, checked)}
                                        disabled={!container.default_properties.processing_overlay}
                                        tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.GTM_PATH_ACTIVE}`)}
                                    />
                                </FieldBlock>
                                <Box radius={BoxRadius.MD}>
                                    <FormLayoutRows>
                                        <FieldBlock
                                            name={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.GTM_ID}`)}
                                            label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.GTM_ID}`)}
                                            required={!!container.default_properties.gtm_path}
                                        >
                                            <InputText
                                                placeholder={textSgtmContainers(`placeholder.${SgtmContainerPropertiesField.GTM_ID}`)}
                                                value={container.default_properties.gtm_id}
                                                onChange={(value) => onChange({[SgtmContainerPropertiesField.GTM_ID]: value})}
                                                disabled={!(container.default_properties.processing_overlay && container.default_properties.gtm_path_active)}
                                            />
                                        </FieldBlock>
                                        <Checkbox
                                            label={textSgtmContainers(`label.${SgtmContainerPropertiesField.GTM_DATALAYER}`)}
                                            name={SgtmContainerPropertiesField.GTM_DATALAYER}
                                            checked={isUseDataLayerCustom}
                                            onChange={handleChangeDataLayerCustom}
                                            disabled={!(container.default_properties.processing_overlay && container.default_properties.gtm_path_active)}
                                        />
                                        {isUseDataLayerCustom &&
                                            <FieldBlock
                                                name={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.GTM_DATALAYER}`)}
                                                label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.GTM_DATALAYER}`)}
                                                required={!!(container.default_properties.processing_overlay && container.default_properties.gtm_path && isUseDataLayerCustom)}
                                            >
                                                <InputText
                                                    placeholder={DEFAULT_GTM_DATALAYER}
                                                    value={container.default_properties.gtm_datalayer === DEFAULT_GTM_DATALAYER ? "" : container.default_properties.gtm_datalayer}
                                                    onChange={(value) => onChange({[SgtmContainerPropertiesField.GTM_DATALAYER]: value})}
                                                    disabled={!(container.default_properties.processing_overlay && container.default_properties.gtm_path_active && isUseDataLayerCustom)}
                                                />
                                            </FieldBlock>
                                        }
                                        <FormLayoutSeparator/>
                                        <FormRowTitle>
                                            <Tag label={textSgtmContainers("tag.experimental")} customColor={{color: Color.WARNING_MAIN, backgroundColor: Color.WARNING_LIGHTER, borderColor: Color.WARNING_LIGHT}}/>
                                        </FormRowTitle>
                                        <FieldBlock label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.GA4_PATH_ACTIVE}`)}>
                                            <ToggleSwitch
                                                label={textSgtmContainers(`label.${SgtmContainerPropertiesField.GA4_PATH_ACTIVE}`)}
                                                name={SgtmContainerPropertiesField.GA4_PATH_ACTIVE}
                                                checked={container.default_properties.ga4_path_active}
                                                onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.GA4_PATH_ACTIVE, checked)}
                                                disabled={!(container.default_properties.processing_overlay && container.default_properties.gtm_path_active)}
                                                tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.GA4_PATH_ACTIVE}`)}
                                            />
                                        </FieldBlock>
                                    </FormLayoutRows>
                                </Box>
                            </FormLayoutRows>
                        </Box>
                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                            <FormLayoutRows>
                                <FormRowTitle>
                                    <Tag label={textSgtmContainers("section.advanced_configuration.enrichment")} style={TagStyle.DEFAULT_OCEAN}/>
                                </FormRowTitle>
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.COOKIELESS_ID_1P}`)}
                                    name={SgtmContainerPropertiesField.COOKIELESS_ID_1P}
                                    checked={container.default_properties.cookieless_id_1p}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.COOKIELESS_ID_1P, checked)}
                                    disabled={!container.default_properties.processing_overlay}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.COOKIELESS_ID_1P}`)}
                                />
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.COOKIELESS_ID_3P}`)}
                                    name={SgtmContainerPropertiesField.COOKIELESS_ID_3P}
                                    checked={container.default_properties.cookieless_id_3p}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.COOKIELESS_ID_3P, checked)}
                                    disabled={!container.default_properties.processing_overlay}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.COOKIELESS_ID_3P}`)}
                                />
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.FIRSTID_ACTIVE}`)}
                                    name={SgtmContainerPropertiesField.FIRSTID_ACTIVE}
                                    checked={container.default_properties.firstid_active}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.FIRSTID_ACTIVE, checked)}
                                    disabled={!container.default_properties.processing_overlay}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.FIRSTID_ACTIVE}`)}
                                />
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.FIRSTID_AUTOLOAD}`)}
                                    name={SgtmContainerPropertiesField.FIRSTID_AUTOLOAD}
                                    checked={container.default_properties.firstid_autoload}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.FIRSTID_AUTOLOAD, checked)}
                                    disabled={!container.default_properties.processing_overlay || !container.default_properties.firstid_active}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.FIRSTID_AUTOLOAD}`)}
                                />
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE}`)}
                                    name={SgtmContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE}
                                    checked={container.default_properties.hashed_mail_enrich_active}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE, checked)}
                                    disabled={!container.default_properties.processing_overlay}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.HASHED_MAIL_ENRICH_ACTIVE}`)}
                                />
                                <Box radius={BoxRadius.MD}>
                                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                        <ToggleSwitch
                                            label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE}`)}
                                            name={SgtmContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE}
                                            checked={container.default_properties.hashed_mail_sharing_active}
                                            onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE, checked)}
                                            disabled={!container.default_properties.processing_overlay || !container.default_properties.hashed_mail_enrich_active}
                                            tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.HASHED_MAIL_SHARING_ACTIVE}`)}
                                        />
                                        <ToggleSwitch
                                            label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE}`)}
                                            name={SgtmContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE}
                                            checked={container.default_properties.hashed_mail_auto_ga4_active}
                                            onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE, checked)}
                                            disabled={!container.default_properties.processing_overlay || !container.default_properties.hashed_mail_enrich_active}
                                            tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.HASHED_MAIL_AUTO_GA4_ACTIVE}`)}
                                        />
                                    </FormLayoutRows>
                                </Box>
                            </FormLayoutRows>
                        </Box>
                    </FormLayoutRows>
                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                            <FormLayoutRows>
                                <FormRowTitle>
                                    <Tag label={textSgtmContainers("section.advanced_configuration.script_optimization")} style={TagStyle.DEFAULT_OCEAN}/>
                                </FormRowTitle>
                                <FieldBlock content={{direction: FlexContentDirection.COLUMN, spacing: FlexContentSpacing.SMALL}}>
                                    <ToggleSwitch
                                        label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.CMP_EMBED}`)}
                                        name={SgtmContainerPropertiesField.CMP_EMBED}
                                        checked={container.default_properties.cmp_embed}
                                        onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.CMP_EMBED, checked)}
                                        disabled={!container.default_properties.processing_overlay}
                                    />
                                    <Box radius={BoxRadius.MD}>
                                        <FormLayoutRows>
                                            <FieldBlock
                                                label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.CMP_CONFIG_ID}`)}
                                                required={container.default_properties.cmp_embed}
                                            >
                                                <SelectAutocomplete
                                                    value={container.default_properties.cmp_config_id}
                                                    placeholder={textSgtmContainers(`placeholder.${SgtmContainerPropertiesField.CMP_CONFIG_ID}`)}
                                                    options={cmpConfigList.map(({id, name, id_partner}) => ({label: `${id} (${name})`, value: id, id_partner}))}
                                                    onChange={(option) => onChange({
                                                        [SgtmContainerPropertiesField.CMP_CONFIG_ID]: option?.value as string || "",
                                                        [SgtmContainerPropertiesField.CMP_PARTNER_ID]: option?.id_partner as number || null
                                                    })}
                                                    disabled={!(container.default_properties.processing_overlay && container.default_properties.cmp_embed)}
                                                    clearable
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </FieldBlock>
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.GTM_ORDER_DATALAYER}`)}
                                    name={SgtmContainerPropertiesField.GTM_ORDER_DATALAYER}
                                    checked={container.default_properties.gtm_order_datalayer}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.GTM_ORDER_DATALAYER, checked)}
                                    disabled={!container.default_properties.processing_overlay}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.GTM_ORDER_DATALAYER}`)}
                                />
                            </FormLayoutRows>
                        </Box>
                        <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                            <FormLayoutRows>
                                <FormRowTitle>
                                    <Tag label={textSgtmContainers("section.advanced_configuration.transparency")} style={TagStyle.DEFAULT_OCEAN}/>
                                </FormRowTitle>
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.APPLY_TRANSPARENCY}`)}
                                    name={SgtmContainerPropertiesField.APPLY_TRANSPARENCY}
                                    checked={container.default_properties.apply_transparency}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.APPLY_TRANSPARENCY, checked)}
                                    disabled={!container.default_properties.processing_overlay}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.APPLY_TRANSPARENCY}`)}
                                />
                                <ToggleSwitch
                                    label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.AVOID_COOKIES}`)}
                                    name={SgtmContainerPropertiesField.AVOID_COOKIES}
                                    checked={container.default_properties.avoid_cookies}
                                    onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.AVOID_COOKIES, checked)}
                                    disabled={!container.default_properties.processing_overlay}
                                    tooltip={textSgtmContainers(`tooltip.${SgtmContainerPropertiesField.AVOID_COOKIES}`)}
                                />
                                <FieldBlock content={{direction: FlexContentDirection.COLUMN, spacing: FlexContentSpacing.SMALL}}>
                                    <ToggleSwitch
                                        label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.HELPER_ACTIVE}`)}
                                        name={SgtmContainerPropertiesField.HELPER_ACTIVE}
                                        checked={container.default_properties.helper_active}
                                        onChange={(checked) => handleChangeProperties(SgtmContainerPropertiesField.HELPER_ACTIVE, checked)}
                                        disabled={!container.default_properties.processing_overlay}
                                    />
                                    <Box radius={BoxRadius.MD}>
                                        <FieldBlock
                                            label={textSgtmContainers(`field.${SgtmContainerField.DEFAULT_PROPERTIES}.${SgtmContainerPropertiesField.HELPER_CONFIG_ID}`)}
                                            required={container.default_properties.helper_active}
                                        >
                                            <SelectAutocomplete
                                                value={container.default_properties.helper_config_id}
                                                placeholder={textSgtmContainers(`placeholder.${SgtmContainerPropertiesField.HELPER_CONFIG_ID}`)}
                                                options={helperDomainList.map(({id, name, partner_id}) => ({label: `${id} (${name})`, value: id, partner_id}))}
                                                onChange={(option) => onChange({
                                                    [SgtmContainerPropertiesField.HELPER_CONFIG_ID]: option?.value as number || null,
                                                    [SgtmContainerPropertiesField.HELPER_PARTNER_ID]: option?.partner_id as number || null
                                                })}
                                                disabled={!(container.default_properties.processing_overlay && container.default_properties.helper_active)}
                                                clearable
                                            />
                                        </FieldBlock>
                                    </Box>
                                </FieldBlock>
                            </FormLayoutRows>
                        </Box>
                    </FormLayoutRows>
                </LayoutColumns>
            </FormLayoutRows>
        </ContentBlock>
    );
};

export default SgtmContainerAdvancedConfiguration;
