import {ApiModel} from "../../../common/api/model/ApiModel";
import {Status} from "../../../utils/Status";
import {PartnerPaymentMethodDetailsDirectDebit} from "./PartnerPaymentMethodDetailsDirectDebit";
import {PartnerPaymentMethodDetailsStripe} from "./PartnerPaymentMethodDetailsStripe";
import {PartnerPaymentMethodMode} from "./PartnerPaymentMethodMode";

export class PartnerPaymentMethod extends ApiModel {
    id: number = 0;
    mode?: PartnerPaymentMethodMode;
    owner_partner_id: number = 0;
    expires_at?: string;
    details: any;
    status: string = Status.REGISTERED.name;
    readonly created_at: string = "";
    readonly updated_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        if (this.mode === PartnerPaymentMethodMode.DIRECT_DEBIT) {
            this.loadObject("details", PartnerPaymentMethodDetailsDirectDebit);
        } else if (this.mode === PartnerPaymentMethodMode.STRIPE) {
            this.loadObject("details", PartnerPaymentMethodDetailsStripe);
        }
    }

    static statuses(): Status[] {
        return [
            Status.REGISTERED,
            Status.VALIDATED,
            Status.BLOCKED
        ];
    }
}
