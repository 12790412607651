import {Box, BoxProps, BoxRadius, ContentBlock, LayoutColumns, LayoutRows, Loadable} from "@sirdata/ui-lib";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {CmpConsentStatDetails} from "../../../api/model/cmp/stat/CmpConsentStatDetails";
import {CmpStatPeriod} from "../../../api/model/cmp/stat/CmpStatPeriod";
import {CmpStatRequest} from "../../../api/model/cmp/stat/CmpStatRequest";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {CHART_DATASET_CONSENT_STYLES, CHART_DATASET_NO_CHOICE_STYLES, CHART_DATASET_OBJECT_STYLES, GraphData, optionsRating} from "../../../utils/cmp/CmpChartOptions";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {ChartCardLegend, ChartCardLegendLabels, ChartContainer, ChartLine, CmpConfigurationAnalyticsMetricCard} from "../index";

type CmpConfigurationAnalyticsConsentProps = {
    statRequest: CmpStatRequest;
};

const CmpConfigurationAnalyticsConsent: FC<CmpConfigurationAnalyticsConsentProps> = ({statRequest}) => {
    const {t: textCmpAnalytics} = useTranslation(TranslationPortalFile.CMP_ANALYTICS);
    const alert = useAlert();
    const [consentStatDetails, setConsentStatDetails] = useState<CmpConsentStatDetails>(new CmpConsentStatDetails());
    const [rateGraphData, setRateGraphData] = useState<GraphData>();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (statRequest.isInvalid()) {
            return;
        }

        (async () => {
            try {
                setLoading(true);
                setConsentStatDetails(await session.restCmpPartner.getConsentStats(statRequest));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("consent stats", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, statRequest]);

    useEffect(() => {
        setRateGraphData({
            labels: consentStatDetails.dates,
            datasets: [{
                label: textCmpAnalytics("metric.consent"),
                data: consentStatDetails.global.daily.consent,
                ...CHART_DATASET_CONSENT_STYLES
            }, {
                label: textCmpAnalytics("metric.object"),
                data: consentStatDetails.global.daily.object,
                ...CHART_DATASET_OBJECT_STYLES
            }, {
                label: textCmpAnalytics("metric.no_choice"),
                data: consentStatDetails.global.daily.no_choice,
                ...CHART_DATASET_NO_CHOICE_STYLES
            }]
        });
    }, [consentStatDetails, textCmpAnalytics]);

    return (
        <Loadable loading={isLoading}>
            <LayoutRows>
                <LayoutColumns>
                    <CmpConfigurationAnalyticsMetricCard
                        header={{label: textCmpAnalytics("metric.consent")}}
                        value={`${consentStatDetails.global.ratings.consent}%`}
                        trendValue={consentStatDetails.global.trends.consent}
                        showTrend={statRequest.period !== CmpStatPeriod.CUSTOM.name}
                    />
                    <CmpConfigurationAnalyticsMetricCard
                        header={{label: textCmpAnalytics("metric.object")}}
                        value={`${consentStatDetails.global.ratings.object}%`}
                        trendValue={consentStatDetails.global.trends.object}
                        showTrend={statRequest.period !== CmpStatPeriod.CUSTOM.name}
                    />
                    <CmpConfigurationAnalyticsMetricCard
                        header={{label: textCmpAnalytics("metric.no_choice")}}
                        value={`${consentStatDetails.global.ratings.no_choice}%`}
                        trendValue={consentStatDetails.global.trends.no_choice}
                        showTrend={statRequest.period !== CmpStatPeriod.CUSTOM.name}
                    />
                </LayoutColumns>
                <ContentBlock header={{title: {label: textCmpAnalytics("evolution_by_rating")}}}>
                    <Box {...BoxProps.SECTION_BLOCK} radius={BoxRadius.MD} cssClass="chart-card">
                        <ChartCardLegend
                            labels={rateGraphData?.datasets.map((dataset) => ({value: dataset.label, color: dataset.borderColor})) as ChartCardLegendLabels[]}
                            alignRight
                        />
                        <ChartContainer height="35rem">
                            <ChartLine data={rateGraphData} options={optionsRating}/>
                        </ChartContainer>
                    </Box>
                </ContentBlock>
            </LayoutRows>
        </Loadable>
    );
};

export default CmpConfigurationAnalyticsConsent;
