import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {SgtmContainerHitStatPerConfPerType} from "./SgtmContainerHitStatPerConfPerType";
import {SgtmContainerHitStatPerConfField} from "./SgtmContainerHitStatPerConfField";

export class SgtmContainerHitStatPerConf extends ApiModel {
    conf_id: number = 0;
    total_hits: number = 0;
    hit_per_type: SgtmContainerHitStatPerConfPerType = new SgtmContainerHitStatPerConfPerType();

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.loadObject(SgtmContainerHitStatPerConfField.HIT_PER_TYPE, SgtmContainerHitStatPerConfPerType);
    }
}
