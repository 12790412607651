import {PAGE_SIZE} from "../../../../utils/constants";
import {SearchParams} from "../../../../utils/SearchParams";
import {SearchParamsField} from "../../../../utils/SearchParamsField";
import {Status} from "../../../../utils/Status";
import {SearchQuery} from "../../../interface/SearchQuery";
import {CmpConfigField} from "./CmpConfigField";

export enum CmpConfigSearchParamsField {
    CREATION_TYPE = "creation_type"
}

export class CmpConfigSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    partner_id?: number;
    status?: string;
    creation_type?: string;

    withSearchParams(params: SearchParams): CmpConfigSearchQuery {
        const newQuery = super.withSearchParams(params).withDefaultSorting(CmpConfigField.CREATION_TIME, true) as CmpConfigSearchQuery;
        newQuery.partner_id = params.getNumber(SearchParamsField.PARTNER);
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.creation_type = params.getString(CmpConfigSearchParamsField.CREATION_TYPE);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            page: this.page,
            size: this.size,
            sort_by: this.sortBy,
            reverse_order: this.reverseOrder,
            partner_id: this.partner_id,
            status: this.status === Status.IN_PROGRESS.name ? Status.INACTIVE.name : this.status,
            in_progress: this.status === Status.IN_PROGRESS.name ? "true" : undefined,
            creation_type: this.creation_type
        };
    }
}
