import {Rest} from "../../common/api/rest/Rest";
import {CmpHitStat} from "../model/cmp/stat/CmpHitStat";
import {CmpStatRequest} from "../model/cmp/stat/CmpStatRequest";
import {CmpConsentStatDetails} from "../model/cmp/stat/CmpConsentStatDetails";

export class RestCmpPartner extends Rest {
    rootPath = "/console-api/cmp/partner";

    getConsentStats(request: CmpStatRequest): Promise<CmpConsentStatDetails> {
        return this._client.get(`${this.rootPath}/${request.partner_id}/stats/consent/${request.config_id}`, CmpConsentStatDetails, {queryParams: request.getJsonParameters()}) as Promise<CmpConsentStatDetails>;
    }

    getHitsStats(request: CmpStatRequest): Promise<CmpHitStat[]> {
        return this._client.get(`${this.rootPath}/${request.partner_id}/stats/hits/${request.config_id}/start/${request.start}/end/${request.end}`, CmpHitStat, {queryParams: request.getJsonParameters()}) as Promise<CmpHitStat[]>;
    }
}
