import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {Formatter} from "../../common/utils/Formatter";
import {MainContentHeader, MainContentHeaderAction, SelectStatus} from "../../component/snippet";
import {Module} from "../../utils/Module";
import {
    Action,
    Box,
    BoxProps,
    BoxRadius,
    BoxSpacing,
    ButtonLink,
    ContentBlock,
    FieldBlock,
    FlexContentSpacing,
    Form,
    FormatterFormat,
    FormLayoutColumns,
    FormLayoutRows,
    FormValidationType,
    IconTooltip,
    InputDate,
    InputDateType,
    InputText,
    InputTextNumber,
    InputUrl,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    SelectAutocomplete,
    TranslationLibFile
} from "@sirdata/ui-lib";
import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {SalesRevenue} from "../../api/model/revenue/sales-revenue/SalesRevenue";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {useNavigate, useParams} from "react-router-dom";
import useAlert from "../../utils/hooks/useAlert";
import {SalesRevenueField} from "../../api/model/revenue/sales-revenue/SalesRevenueField";
import {Authorization} from "../../api/model/account/Authorization";
import {detectChanges} from "../../common/utils/portal";
import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {Status} from "../../utils/Status";
import {RevenueSeatSearchQuery} from "../../api/model/revenue/seat/RevenueSeatSearchQuery";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {RevenueSeatField} from "../../api/model/revenue/seat/RevenueSeatField";
import {SalesRevenueFilters} from "../../api/model/revenue/sales-revenue/SalesRevenueFilters";
import {RevenueSeat} from "../../api/model/revenue/seat/RevenueSeat";
import {SalesRevenueCostType} from "../../api/model/revenue/sales-revenue/SalesRevenueCostType";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";

function MonthlyRevenuesDetails() {
    const alert = useAlert();
    const navigate = useNavigate();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMonthlyRevenues} = useTranslation(TranslationPortalFile.MONTHLY_REVENUES);
    const {t: textSeats} = useTranslation(TranslationPortalFile.SEATS);
    const {id} = useParams() as { id: string };
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);

    const FORM_ID = "form-edit-monthly-revenue";
    const [revenue, setRevenue] = useState<SalesRevenue>(new SalesRevenue());
    const [initRevenue, setInitRevenue] = useState<SalesRevenue>(new SalesRevenue());
    const [filters, setFilters] = useState<SalesRevenueFilters>(new SalesRevenueFilters());
    const [searchOwnerId, setSearchOwnerId] = useState<number>();
    const [seats, setSeats] = useState<RevenueSeat[]>([]);
    const [selectedSeat, setSelectedSeat] = useState<RevenueSeat>();

    const loadSeats = useCallback(async () => {
        try {
            const searchResult = await session.restSeat.search(new RevenueSeatSearchQuery({
                [SearchParamsField.SIZE]: 10000,
                [RevenueSeatField.OWNER_ID]: searchOwnerId
            }));
            setSeats(searchResult.elements);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("seats", e.message);
            }
        }
    }, [alert, searchOwnerId]);

    useEffect(() => {
        (async () => {
            try {
                const filters = await session.restSalesRevenue.getFilters();
                setFilters(filters);
                setSearchOwnerId(filters.owners.find((user) => user.id === session.userId)?.id);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("filters", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                const revenue = await session.restSalesRevenue.get(+id);
                if (!revenue.invoice_urls.length) {
                    revenue.invoice_urls.push("");
                }
                setRevenue(revenue);
                setInitRevenue(new SalesRevenue(revenue));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("revenue", e.message);
                    }
                }
            } finally {
                setLoading(false);
                await loadSeats();
            }
        })();
    }, [id, alert, loadSeats]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(revenue, initRevenue));
    }, [revenue, initRevenue]);

    useEffect(() => {
        setSelectedSeat(seats.find((it) => it.id === revenue.seat_id));
    }, [seats, revenue.seat_id]);

    const handleChange = (field: SalesRevenueField, value: any) => {
        setRevenue((prevState) => new SalesRevenue({...prevState, [field]: value}));
    };

    const handleChangeSearchOwnerId = (ownerId: number) => {
        setSearchOwnerId(ownerId);
        if (ownerId && selectedSeat?.owner?.id !== ownerId) {
            handleChange(SalesRevenueField.SEAT_ID, 0);
        }
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const updatedRevenue = await session.restSalesRevenue.update(revenue);
            if (!updatedRevenue.invoice_urls.length) {
                updatedRevenue.invoice_urls.push("");
            }
            setRevenue(updatedRevenue);
            setInitRevenue(new SalesRevenue(updatedRevenue));
            alert.updateWithSuccess("revenue");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("revenue", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restSalesRevenue.delete(revenue.id);
            navigate(Module.MONTHLY_REVENUES.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("revenue", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleValidate = async () => {
        try {
            const validatedRevenue = await session.restSalesRevenue.validate(revenue);
            handleChange(SalesRevenueField.STATUS, validatedRevenue.status);
            setInitRevenue(new SalesRevenue(validatedRevenue));
            alert.updateWithSuccess("revenue");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("revenue", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.MONTHLY_REVENUES} element={initRevenue.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.SALES_REVENUES_ADMIN.name}>
                    {initRevenue.status === Status.TO_VALIDATE.name && <MainContentHeaderAction action={Action.VALIDATE} onClick={handleValidate}/>}
                </RestrictedContent>
                <RestrictedContent allowedTo={Authorization.SALES_REVENUES.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <RestrictedContent allowedTo={initRevenue.status === Status.VALIDATED.name ? Authorization.SALES_REVENUES_ADMIN.name : Authorization.SALES_REVENUES.update}>
                        <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                    </RestrictedContent>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <Loadable loading={isLoading}>
                            <LayoutColumns>
                                <ContentBlock header={{title: {label: textMonthlyRevenues("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.STATUS}`)}>
                                                    <SelectStatus
                                                        value={revenue.status}
                                                        statuses={revenue.status === Status.VALIDATED.name ? [Status.VALIDATED] : SalesRevenue.statuses().filter((it) => it !== Status.DELETED && it !== Status.VALIDATED)}
                                                        onChange={(status) => handleChange(SalesRevenueField.STATUS, status?.name)}
                                                        disabled={revenue.status === Status.VALIDATED.name}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.DATE}`)} required>
                                                    <InputDate
                                                        value={revenue.date}
                                                        onChange={(value) => handleChange(SalesRevenueField.DATE, value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.BILLING_MONTH}`)}>
                                                    <InputDate
                                                        type={InputDateType.MONTH}
                                                        value={Formatter.formatDate(revenue.billing_month, FormatterFormat.MONTH_INPUT)}
                                                        onChange={() => void 0}
                                                        disabled
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                                <FieldBlock label={textMonthlyRevenues("search.seats_by_owner")} inline>
                                                    <SelectAutocomplete
                                                        value={searchOwnerId}
                                                        options={filters.owners.map((it) => ({value: it.id, label: it.fullName}))}
                                                        onChange={(option) => handleChangeSearchOwnerId(option ? +option.value : 0)}
                                                        clearable
                                                    />
                                                </FieldBlock>
                                                <Box radius={BoxRadius.MD} spacing={BoxSpacing.MEDIUM}>
                                                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                        <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.SEAT}`)} required>
                                                            <SelectAutocomplete
                                                                value={revenue.seat_id}
                                                                options={seats.map((it) => ({value: it.id, label: it.label()}))}
                                                                onChange={(option) => handleChange(SalesRevenueField.SEAT_ID, option?.value)}
                                                                clearable
                                                            />
                                                        </FieldBlock>
                                                        <FormLayoutColumns>
                                                            <FieldBlock label={textSeats(`field.${RevenueSeatField.ORGANIZATION}`)}>
                                                                <InputText
                                                                    value={selectedSeat?.organization?.name || ""}
                                                                    onChange={() => void 0}
                                                                    disabled
                                                                />
                                                            </FieldBlock>
                                                            <FieldBlock label={textSeats(`field.${RevenueSeatField.OWNER}`)}>
                                                                <InputText
                                                                    value={selectedSeat?.owner?.name || ""}
                                                                    onChange={() => void 0}
                                                                    disabled
                                                                />
                                                            </FieldBlock>
                                                        </FormLayoutColumns>
                                                    </FormLayoutRows>
                                                </Box>
                                            </FormLayoutRows>
                                            <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.PRODUCT}`)} required>
                                                <SelectAutocomplete
                                                    value={revenue.product_id}
                                                    options={filters.products.map((it) => ({value: it.id, label: it.label}))}
                                                    onChange={(option) => handleChange(SalesRevenueField.PRODUCT_ID, option?.value)}
                                                />
                                            </FieldBlock>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                                <ContentBlock header={{title: {label: textMonthlyRevenues("section.details")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns>
                                                <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                                    <FieldBlock
                                                        label={textMonthlyRevenues(`field.${SalesRevenueField.REVENUE}`)}
                                                        required={revenue.status === Status.TO_VALIDATE.name}
                                                    >
                                                        <InputTextNumber
                                                            value={revenue.revenue && Formatter.convertCentsToUnits(revenue.revenue)}
                                                            onChange={(value) => handleChange(SalesRevenueField.REVENUE, !!value ? Formatter.convertUnitsToCents(value) : undefined)}
                                                        />
                                                        <Select
                                                            value={revenue.currency}
                                                            options={SalesRevenue.currencies().map((it) => ({
                                                                value: it.currency,
                                                                label: it.label
                                                            }))}
                                                            onChange={(option) => handleChange(SalesRevenueField.CURRENCY, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutRows>
                                                <FormLayoutColumns>
                                                    <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.BIDDER_PLATFORM_NAME}`)}>
                                                        <SelectAutocomplete
                                                            value={revenue.bidder_platform_name}
                                                            options={filters.platforms.map((it) => ({value: it, label: it}))}
                                                            onChange={(option) => handleChange(SalesRevenueField.BIDDER_PLATFORM_NAME, option?.value)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.IMPRESSION}`)}>
                                                        <InputTextNumber
                                                            value={revenue.impression}
                                                            onChange={(value) => handleChange(SalesRevenueField.IMPRESSION, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textMonthlyRevenues(`field.${SalesRevenueField.COST_AMOUNT}`)}>
                                                    <InputTextNumber
                                                        value={Formatter.convertCentsToUnits(revenue.cost_amount)}
                                                        onChange={(value) => handleChange(SalesRevenueField.COST_AMOUNT, Formatter.convertUnitsToCents(value))}
                                                        min={0}
                                                    />
                                                    <Select
                                                        value={revenue.cost_currency}
                                                        options={SalesRevenue.currencies().map((it) => ({
                                                            value: it.currency,
                                                            label: it.label
                                                        }))}
                                                        onChange={(option) => handleChange(SalesRevenueField.COST_CURRENCY, option?.value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock
                                                    label={textMonthlyRevenues(`field.${SalesRevenueField.COST_TYPE}.`)}
                                                    required={!!revenue.cost_amount}
                                                >
                                                    <Select
                                                        id={SalesRevenueField.COST_TYPE}
                                                        value={revenue.cost_type}
                                                        options={SalesRevenueCostType.values().map((it) => ({
                                                            value: it,
                                                            label: textMonthlyRevenues(`field.${SalesRevenueField.COST_TYPE}.${it}`)
                                                        }))}
                                                        onChange={(option) => handleChange(SalesRevenueField.COST_TYPE, option?.value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock
                                                label={textMonthlyRevenues(`field.${SalesRevenueField.COST_ORGANIZATION}`)}
                                                required={!!revenue.cost_amount}
                                            >
                                                <SelectAutocomplete
                                                    value={revenue.cost_organization_id}
                                                    options={filters.organizations.map((it) => ({value: it.id, label: it.name}))}
                                                    onChange={(option) => handleChange(SalesRevenueField.COST_ORGANIZATION_ID, option?.value)}
                                                    clearable
                                                />
                                            </FieldBlock>
                                            <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                                {revenue.invoice_urls.map((invoiceUrl, index) =>
                                                    index === 0 ?
                                                        <FieldBlock
                                                            key={`invoice-${index.toString()}`}
                                                            label={textMonthlyRevenues(`field.${SalesRevenueField.INVOICE_URLS}`)}
                                                            actions={(
                                                                !!revenue.invoice_urls[revenue.invoice_urls.length - 1] ?
                                                                    <ButtonLink
                                                                        icon={{name: "add_circle"}}
                                                                        onClick={() => handleChange(SalesRevenueField.INVOICE_URLS, [...revenue.invoice_urls, ""])}
                                                                        reverseUnderline
                                                                    >
                                                                        {textCommon(Action.ADD.labelKey)}
                                                                    </ButtonLink> : undefined
                                                            )}
                                                            required={revenue.status === Status.TO_VALIDATE.name}
                                                        >
                                                            <InputUrl
                                                                value={invoiceUrl}
                                                                onChange={(value) => handleChange(SalesRevenueField.INVOICE_URLS, revenue.invoice_urls.map((it, idx) => index === idx ? value : it))}
                                                            />
                                                        </FieldBlock> :
                                                        <FieldBlock key={`invoice-${index.toString()}`}>
                                                            <InputUrl
                                                                value={invoiceUrl}
                                                                onChange={(value) => handleChange(SalesRevenueField.INVOICE_URLS, revenue.invoice_urls.map((it, idx) => index === idx ? value : it))}
                                                            />
                                                            {revenue.invoice_urls.length > 1 &&
                                                                <IconTooltip
                                                                    icon={Action.REMOVE.icon}
                                                                    text={textCommon(Action.REMOVE.labelKey)}
                                                                    onClick={() => handleChange(SalesRevenueField.INVOICE_URLS, revenue.invoice_urls.filter((_, idx) => index !== idx))}
                                                                />
                                                            }
                                                        </FieldBlock>
                                                )}
                                            </FormLayoutRows>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </LayoutColumns>
                        </Loadable>
                    </LayoutRows>
                </Form>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="revenue"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default MonthlyRevenuesDetails;
