import {ApiModel} from "../../../common/api/model/ApiModel";

export enum PartnerPaymentMethodDetailsStripeType {
    CARD = "card",
    SEPA_DEBIT = "sepa_debit"
}

export class PartnerPaymentMethodStripeDetailsStripeCard extends ApiModel {
    readonly type: PartnerPaymentMethodDetailsStripeType = PartnerPaymentMethodDetailsStripeType.CARD;
    readonly card: {
        brand: string;
        last4: string;
        exp_month: number;
        exp_year: number;
    } = {brand: "", last4: "", exp_month: 0, exp_year: 0};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export class PartnerPaymentMethodStripeDetailsStripeSepaDebit extends ApiModel {
    readonly type: PartnerPaymentMethodDetailsStripeType = PartnerPaymentMethodDetailsStripeType.SEPA_DEBIT;
    readonly sepa_debit: {
        country: string;
        last4: string;
    } = {country: "", last4: ""};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export class PartnerPaymentMethodDetailsStripe extends ApiModel {
    stripe: PartnerPaymentMethodStripeDetailsStripeCard | PartnerPaymentMethodStripeDetailsStripeSepaDebit = new PartnerPaymentMethodStripeDetailsStripeCard();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any) {
        if (!o) return;
        super.load(o);
        if (this.stripe.type === PartnerPaymentMethodDetailsStripeType.SEPA_DEBIT) {
            this.loadObject("stripe", PartnerPaymentMethodStripeDetailsStripeSepaDebit);
        } else {
            this.loadObject("stripe", PartnerPaymentMethodStripeDetailsStripeCard);
        }
    }
}
