import {ApiModel} from "../../../../common/api/model/ApiModel";

export class SalesRevenueProperties extends ApiModel {
    total_revenue: number = 0;
    total_cost_amount: number = 0;
    total_margin: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
