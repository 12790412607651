import {ApiModel} from "../../../common/api/model/ApiModel";

export class PartnerPaymentMethodDetailsDirectDebit extends ApiModel {
    direct_debit: {
        country: string;
        last4: string;
    } = {country: "", last4: ""};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
