import {SearchResult} from "../../../interface/SearchResult";
import {SalesRevenue} from "./SalesRevenue";
import {SalesRevenueProperties} from "./SalesRevenueProperties";

export class SalesRevenueSearchResult extends SearchResult<SalesRevenue> {
    properties: SalesRevenueProperties = new SalesRevenueProperties();

    constructor(o?: {}) {
        super(SalesRevenue, o);
    }
}
