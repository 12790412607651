import {Rest} from "../../common/api/rest/Rest";
import {ApiDocumentationItem} from "../model/api-documentation/ApiDocumentationItem";

export class RestDocs extends Rest {
    rootPath = "/console-api/docs";

    list(): Promise<ApiDocumentationItem[]> {
        return this._client.get(`${this.rootPath}`, ApiDocumentationItem) as Promise<ApiDocumentationItem[]>;
    };
}
