import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Module} from "../../../../utils/Module";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {SgtmContainerProperties} from "./SgtmContainerProperties";
import {SgtmContainerField} from "./SgtmContainerField";
import {SgtmContainerHostDetail} from "./SgtmContainerHostDetail";
import {SgtmContainerHostProperties} from "./SgtmContainerHostProperties";
import {SgtmContainerDeploymentDestination} from "./SgtmContainerDeploymentDestination";
import {PathHelper} from "../../../../common/utils/PathHelper";
import {pathSgtmContainersAnalytics} from "../../../../utils/constants";

export class SgtmContainer extends ApiModel {
    readonly id: number = 0;
    partner_id: number = 0;
    name: string = "";
    container_config_id: string = "";
    default_properties: SgtmContainerProperties = new SgtmContainerProperties();
    override_properties_per_host_list?: SgtmContainerHostProperties[];
    instance_hosts: string[] = [];
    helper_active: boolean = false;
    status: string = Status.ACTIVE.name;
    host_details: SgtmContainerHostDetail[] = [];
    deployment_destination: string = SgtmContainerDeploymentDestination.SIRDATA;
    readonly instance_status: string = Status.OFFLINE.name;
    readonly creation_ts: string = "";
    readonly last_update_ts: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static statuses(): Status[] {
        return [
            Status.ACTIVE,
            Status.INACTIVE,
            Status.DELETED
        ];
    }

    static instanceStatuses(): Status[] {
        return [
            Status.OFFLINE,
            Status.UNAVAILABLE,
            Status.WAITING_FOR_START,
            Status.RELOADING,
            Status.ERROR,
            Status.OK
        ];
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                switch (key) {
                    case SgtmContainerField.DEFAULT_PROPERTIES:
                        this[key] = new SgtmContainerProperties(o[key]);
                        break;
                    case SgtmContainerField.OVERRIDE_PROPERTIES_PER_HOST_LIST:
                        if (o[key]) {
                            this.override_properties_per_host_list = (o[key] as object[]).map((it) => new SgtmContainerHostProperties(it));
                        }
                        break;
                    case SgtmContainerField.HOST_DETAILS:
                        this.host_details = (o[key] as object[]).map((it) => new SgtmContainerHostDetail(it));
                        break;
                    default:
                        this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            partner_id: this.partner_id,
            name: this.name,
            container_config_id: this.container_config_id,
            default_properties: this.default_properties.getJsonParameters(),
            override_properties_per_host_list: this.override_properties_per_host_list?.map((it) => it.getJsonParameters()),
            instance_hosts: this.instance_hosts,
            helper_active: this.helper_active,
            status: this.status,
            deployment_destination: this.deployment_destination
        };
    }

    getStatus(): Status {
        return this.status === Status.ACTIVE.name ? Status.ACTIVE : Status.INACTIVE;
    }

    getRoute(): string {
        return Module.SGTM_CONTAINERS.buildRoute(this.id);
    }

    getRouteAnalytics(): string {
        return PathHelper.buildPathWithId(pathSgtmContainersAnalytics, this.id);
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, this.name);
        element.status = this.getStatus();

        if (this.creation_ts) {
            element.createdAt = new Date(this.creation_ts);
        }
        if (this.last_update_ts) {
            element.lastUpdated = new Date(this.last_update_ts);
        }
        return element;
    }

    static getRandomPath(): string {
        const CHARS = "abcdefghijklmnopqrstuvwxyz";
        const LENGTHS = [6, 7, 8, 9];
        let randomPath: string = "";

        for (let i = 0; i < LENGTHS[Math.floor(Math.random() * LENGTHS.length)]; i++) {
            randomPath = randomPath + CHARS[Math.floor(Math.random() * CHARS.length)];
        }

        return `/${randomPath}`;
    }

    static getRandomHost(): string {
        const CHARS = "abcdefghijklmnopqrstuvwxyz";
        const RANDOM_HOST_LENGTH = 6;
        let host: string = "";

        for (let i = 0; i < RANDOM_HOST_LENGTH; i++) {
            host = host + CHARS[Math.floor(Math.random() * CHARS.length)];
        }

        return host;
    }
}
