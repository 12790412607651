import {TagStyle} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {ApiService} from "../../../api/model/ApiService";
import {Tag} from "../index";

export type LabelServiceProps = {
    service: string;
    style?: TagStyle;
};

const LabelService: FunctionComponent<LabelServiceProps> = ({service, style = TagStyle.PRIMARY_MIDNIGHT_LIGHT}) => {
    if (!service) return <></>;

    return (
        <Tag label={ApiService.getLabelFromName(service)} style={style}/>
    );
};

export default LabelService;
