import {Action, AlertSeverity, ContentBlock, ContentBlockAction, Loadable, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {FC, RefObject, useEffect, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {SegmentLinkExternalRow} from "..";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {Distribution} from "../../../api/model/audience/distribution/Distribution";
import {Segment} from "../../../api/model/audience/segment/Segment";
import {SegmentLinkExternal} from "../../../api/model/audience/segment/SegmentLinkExternal";
import {SegmentLinkExternalField} from "../../../api/model/audience/segment/SegmentLinkExternalField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {copyApiModelArray, sortItems} from "../../../common/utils/helper";
import {HandleSaveRef} from "../../../utils/audience/HandleSaveRef";
import {TranslationPortalFile} from "../../../utils/constants";
import {detectChanges} from "../../../common/utils/portal";
import ModalConfirmMessage from "../../modal/ModalConfirmMessage";
import ModalConfirmRemove from "../../modal/ModalConfirmRemove";
import ModalAddSegmentLinksExternal from "../../modal/segments/ModalAddSegmentLinksExternal";
import useAlert from "../../../utils/hooks/useAlert";
import {SegmentsDetailsSubModule} from "../../../screen/segments/SegmentsDetails";

type SegmentExternalLinksProps = {
    segment: Segment;
    handleSaveRef: RefObject<HandleSaveRef>;
    onChange: (module: SegmentsDetailsSubModule, hasChanges: boolean) => void;
};

const SegmentExternalLinks: FC<SegmentExternalLinksProps> = ({segment, handleSaveRef, onChange}) => {
    const alert = useAlert();
    const {t: textSegments} = useTranslation(TranslationPortalFile.SEGMENTS);
    const [isLoading, setLoading] = useState(true);

    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [externalLinks, setExternalLinks] = useState<SegmentLinkExternal[]>([]);
    const [initExternalLinks, setInitExternalLinks] = useState<SegmentLinkExternal[]>([]);

    const [isShowModalAddSegmentLinksExternal, setShowModalAddSegmentLinksExternal] = useState(false);
    const [activeDisableLink, setActiveDisableLink] = useState<SegmentLinkExternal>();
    const [activeRemoveLink, setActiveRemoveLink] = useState<SegmentLinkExternal>();

    useEffect(() => {
        if (!segment.id) return;
        (async () => {
            try {
                const newExternalLinks = await session.restSegmentLinkExternal.list(segment.id);
                const distributions = await session.restList.getDistributions();
                setDistributions(distributions);
                const mandatoryDistributions = distributions.filter((it) => it.on_creation).map((it) => it.name);
                const links = newExternalLinks.filter((it) => it.active || it.value.length > 0 || mandatoryDistributions.includes(it.map_name));
                const sortedLinks = sortItems(links, SegmentLinkExternalField.MAP_LABEL);
                setExternalLinks(sortedLinks);
                setInitExternalLinks(copyApiModelArray(SegmentLinkExternal, sortedLinks));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("external links", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [segment.id, alert]);

    useEffect(() => {
        if (segment.id && !segment.active && externalLinks.some((it) => it.active)) {
            handleToggleLinksActivation(false);
            alert.push(textSegments("message.all_distributions_deactivated"), AlertSeverity.WARNING);
        }
    }, [segment.id, segment.active, externalLinks, alert, textSegments]);

    useEffect(() => {
        onChange(SegmentsDetailsSubModule.LINKS_EXTERNAL, detectChanges(externalLinks, initExternalLinks));
    }, [onChange, externalLinks, initExternalLinks]);

    useImperativeHandle(handleSaveRef, () => ({
        handleSave: async () => {
            setLoading(true);
            try {
                const newExternalLinks = await session.restSegmentLinkExternal.update(segment.id, externalLinks);
                const sortedLinks = sortItems(newExternalLinks, SegmentLinkExternalField.MAP_LABEL);
                setExternalLinks(sortedLinks);
                setInitExternalLinks(copyApiModelArray(SegmentLinkExternal, sortedLinks));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToUpdate("external links", e.message);
                }
            } finally {
                setLoading(false);
            }
        }
    }));

    const handleAddLinks = (links: SegmentLinkExternal[]) => {
        const newExternalLinks = sortItems([...externalLinks, ...links], SegmentLinkExternalField.MAP_LABEL);
        setExternalLinks(newExternalLinks);
        setShowModalAddSegmentLinksExternal(false);
    };

    const handleChange = (link: SegmentLinkExternal) => {
        const newExternalLinks = [...externalLinks];
        const index = newExternalLinks.findIndex((it) => it.map_name === link.map_name);
        newExternalLinks[index] = link;
        setExternalLinks(newExternalLinks);
    };

    const handleDisableLink = (link?: SegmentLinkExternal) => {
        if (!link) return;
        link.active = false;
        handleChange(link);
        setActiveDisableLink(undefined);
    };

    const handleRemoveLink = (link?: SegmentLinkExternal) => {
        if (!link) return;
        const newExternalLinks = externalLinks.filter((it) => it.map_name !== link.map_name);
        setExternalLinks(newExternalLinks);
        setActiveRemoveLink(undefined);
    };

    const handleToggleLinksActivation = (active: boolean) => {
        setExternalLinks((prevState) => prevState.map((link) => new SegmentLinkExternal({...link, [SegmentLinkExternalField.ACTIVE]: active})));
    };

    const getDistributionName = (link?: SegmentLinkExternal): string => {
        if (!link) return "";
        const distribution = distributions.find((it) => it.name === link.map_name);
        return distribution?.label || link.map_name;
    };

    return (
        <Loadable loading={isLoading}>
            <ContentBlock
                header={{title: {label: textSegments("section.distributions")}, actions: [
                    <RestrictedContent key="actions-segment-link-external" allowedTo={Authorization.SEGMENTS.update}>
                        <ContentBlockAction
                            action={new Action(textSegments("actions.activate_all"), Action.ACTIVATE.icon)}
                            onClick={() => handleToggleLinksActivation(true)}
                            isDisabled={!segment.active}
                        />
                        <ContentBlockAction
                            action={Action.ADD}
                            onClick={() => setShowModalAddSegmentLinksExternal(true)}
                        />
                    </RestrictedContent>
                ]}}
            >
                <Table
                    columns={[
                        {label: textSegments("field.external.status"), width: 20, styles: TableColumnStyle.ALIGN_CENTER},
                        {label: textSegments("field.external.name"), width: 30},
                        {label: textSegments("field.external.id"), width: 40},
                        {width: 10}
                    ]}
                >
                    {externalLinks.map((link) =>
                        <SegmentLinkExternalRow
                            key={link.map_name}
                            link={link}
                            onChangeStatus={(item) => item.active ? handleChange(item) : setActiveDisableLink(item)}
                            onChangeValue={handleChange}
                            onRemove={() => setActiveRemoveLink(link)}
                            disabled={!segment.active}
                        />
                    )}
                </Table>
                <ModalAddSegmentLinksExternal
                    active={isShowModalAddSegmentLinksExternal}
                    segment={segment}
                    currentLinks={externalLinks}
                    onSubmit={handleAddLinks}
                    onClose={() => setShowModalAddSegmentLinksExternal(false)}
                />
                <ModalConfirmMessage
                    active={!!activeDisableLink}
                    message={textSegments("message.confirm_link_external_disable", {platform: getDistributionName(activeDisableLink)})}
                    confirm={() => handleDisableLink(activeDisableLink)}
                    confirmAction={Action.DEACTIVATE.labelKey}
                    cancel={() => setActiveDisableLink(undefined)}
                />
                <ModalConfirmRemove
                    active={!!activeRemoveLink}
                    entity={`distribution "${getDistributionName(activeRemoveLink)}"`}
                    confirm={() => handleRemoveLink(activeRemoveLink)}
                    cancel={() => setActiveRemoveLink(undefined)}
                />
            </ContentBlock>
        </Loadable>
    );
};

export default SegmentExternalLinks;
