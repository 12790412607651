import moment from "moment";
import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Formatter} from "../../../../common/utils/Formatter";
import {CmpConsentStatEnvironment} from "./CmpConsentStatEnvironment";
import {CmpStatPeriod} from "./CmpStatPeriod";

export class CmpStatRequest extends ApiModel {
    config_id: string = "";
    partner_id: number | undefined;
    period: string = "";
    start: string = "";
    end: string = "";
    environment: CmpConsentStatEnvironment = CmpConsentStatEnvironment.WEB;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            config_id: this.config_id,
            partner_id: this.partner_id,
            period: this.period,
            start: Formatter.formatDate(this.start, Formatter.FILE_DATE_FORMAT),
            end: Formatter.formatDate(this.end, Formatter.FILE_DATE_FORMAT),
            environment: this.environment
        };
    }

    withPeriod(period: CmpStatPeriod): CmpStatRequest {
        this.period = period.name;
        this.start = period.interval.start;
        this.end = period.interval.end;
        return this;
    }

    isInvalid(): boolean {
        if (!this.config_id || !this.partner_id || !this.start || !this.end) return true;
        if (moment(this.start).diff(moment(), "day") > 0) return true;
        return moment(this.start).diff(this.end, "day") > 0;
    }
}
