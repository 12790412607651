import {Action, ContentBlock, LayoutRows, Loadable, SearchError, SearchField, SearchToolbar, Select, SelectMultiple, Table, TableColumnStyle} from "@sirdata/ui-lib";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {Partner} from "../../api/model/partner/Partner";
import {PartnerField} from "../../api/model/partner/PartnerField";
import {PartnerSearchParamsField, PartnerSearchQuery} from "../../api/model/partner/PartnerSearchQuery";
import {PartnerSearchResult} from "../../api/model/partner/PartnerSearchResult";
import {PartnerService} from "../../api/model/partner/PartnerService";
import {PartnerServiceField} from "../../api/model/partner/PartnerServiceField";
import {UserField} from "../../api/model/user/UserField";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {Formatter} from "../../common/utils/Formatter";
import {buildAndDownloadCSV} from "../../common/utils/portal";
import ModalCreatePartner from "../../component/modal/partners/ModalCreatePartner";
import {MainContentHeader, MainContentHeaderAction, PartnerRow, SelectStatus, SelectUser} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import useSearch from "../../utils/hooks/useSearch";
import {Module} from "../../utils/Module";
import {SearchParamsField} from "../../utils/SearchParamsField";
import {Status} from "../../utils/Status";

function Partners() {
    const {t: textPartners} = useTranslation(TranslationPortalFile.PARTNERS);
    const [isLoading, setLoading] = useState(true);
    const [isShowModalCreatePartner, setShowModalCreatePartner] = useState(false);
    const [cmpPremiumIds, setCmpPremiumIds] = useState<number[]>([]);
    const alert = useAlert();
    const {setSearchResult, ...search} = useSearch(Partner, PartnerSearchQuery, PartnerSearchResult);

    useEffect(() => {
        (async () => {
            try {
                setCmpPremiumIds(await session.restPartner.getCmpPremiums());
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("cmp premium partner ids", e.message);
                }
            }
        })();
    }, [alert]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const partners = await session.restPartner.search(search.searchQuery);
                setSearchResult(partners);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("partners", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, search.searchQuery, setSearchResult]);

    const exportCMPPremiumPartners = async () => {
        try {
            const partners = await session.restPartner.list();
            const headers = [
                "ID",
                "Email",
                "Company"
            ];
            const items = partners.filter((it) => cmpPremiumIds.includes(it.id)).map((partner) => [
                partner.id,
                partner.email,
                partner.company
            ]);
            buildAndDownloadCSV("partners-cmp-premium", headers, items);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("all partners", e.message);
            }
        }
    };

    const exportPartnersList = async () => {
        try {
            const partners = await session.restPartner.list();
            const headers = [
                "ID",
                "Company",
                "Name",
                "Email",
                "Creation Date",
                "Creation Service",
                "Owner",
                "Country",
                "Axciom ID",
                "Branded",
                "Consent",
                "Data",
                "Categorizer",
                "Active"
            ];
            const items = partners.map((partner) => [
                partner.id,
                partner.company,
                partner.fullName,
                partner.email,
                Formatter.formatDate(partner.created_at, Formatter.DATETIME_FORMAT),
                partner.creation_service,
                partner.user,
                partner.country,
                partner.axciom_id,
                partner.branded ? "YES" : "NO",
                partner.consent_status,
                partner.data,
                partner.categorize_request,
                partner.active
            ]);
            buildAndDownloadCSV("partners", headers, items);
        }  catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToLoad("all partners", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContentHeader module={Module.PARTNERS}>
                <RestrictedContent allowedTo={Authorization.PARTNERS.update}>
                    <MainContentHeaderAction action={Action.DOWNLOAD} label={"CMP Premium"} onClick={exportCMPPremiumPartners}/>
                    <MainContentHeaderAction action={Action.DOWNLOAD_ALL} onClick={exportPartnersList}/>
                    <MainContentHeaderAction action={Action.NEW} onClick={() => setShowModalCreatePartner(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <ContentBlock>
                        <SearchToolbar
                            searchBar={{placeholder: textPartners("search.placeholder"), value: search.searchQuery.query, onSubmit: search.changeQuery}}
                            canHideFilters
                        >
                            <SearchField label={textPartners("field.status")}>
                                <SelectStatus
                                    value={search.searchQuery.status}
                                    statuses={Status.getActiveStatuses()}
                                    onChange={(status) => search.changeParam(SearchParamsField.STATUS, status?.name)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textPartners(`field.${PartnerField.SERVICES}.`)}>
                                <SelectMultiple
                                    values={search.searchQuery.services?.split(",")}
                                    options={PartnerService.services().map((it) => ({label: it.label, value: it.name}))}
                                    onChange={(options) => search.changeParam(PartnerSearchParamsField.SERVICES, options?.map((it) => it.value).join(","))}
                                />
                            </SearchField>
                            <SearchField label={textPartners(`search.${PartnerServiceField.IS_LIVE}`)}>
                                <Select
                                    value={search.searchQuery.is_live}
                                    options={[{label: textPartners("search.has_hits"), value: true}, {label: textPartners("search.none"), value: false}]}
                                    onChange={(option) => search.changeParam(PartnerSearchParamsField.IS_LIVE, option?.value)}
                                    clearable
                                />
                            </SearchField>
                            <SearchField label={textPartners(`field.${PartnerField.USER}`)}>
                                <SelectUser
                                    value={search.searchQuery.owner}
                                    field={UserField.ID}
                                    onChange={(user) => search.changeParam(SearchParamsField.OWNER, user?.id)}
                                />
                            </SearchField>
                            <SearchField label={textPartners(`search.${PartnerField.ALLOWS_SIRDATA_ACCESS}`)}>
                                <Select
                                    value={search.searchQuery.allows_sirdata_access}
                                    options={[{label: textPartners("search.yes"), value: true}, {label: textPartners("search.no"), value: false}]}
                                    onChange={(option) => search.changeParam(PartnerSearchParamsField.ALLOWS_SIRDATA_ACCESS, option?.value)}
                                    clearable
                                />
                            </SearchField>
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            {!!search.searchResult.elements.length ?
                                <Table
                                    onSort={search.changeSortOrder}
                                    columns={[
                                        {width: 5, label: textPartners("field.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 5, label: textPartners(`field.${PartnerField.ID}`), styles: TableColumnStyle.ALIGN_CENTER, sort: {field: PartnerField.ID}},
                                        {width: 20, label: textPartners(`field.${PartnerField.COMPANY}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: PartnerField.COMPANY}},
                                        {width: 15, label: textPartners("field.name"), sort: {field: PartnerField.LAST_NAME}},
                                        {width: 25, label: textPartners(`field.${PartnerField.EMAIL}`), styles: [TableColumnStyle.FIXED_WIDTH, TableColumnStyle.HIDE_SCREEN_MEDIUM], sort: {field: PartnerField.EMAIL}},
                                        {width: 10, label: textPartners(`field.${PartnerField.CREATED_AT}`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM, sort: {field: PartnerField.CREATED_AT}},
                                        {width: 10, label: textPartners(`field.${PartnerField.SERVICES}.`), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 5, label: textPartners(`field.${PartnerField.USER}`), styles: [TableColumnStyle.HIDE_SCREEN_MEDIUM]},
                                        {width: 5}
                                    ]}
                                    pagination={search.searchResult.getPagination(search.changePage)}
                                >
                                    {search.searchResult.elements.map((partner: Partner) =>
                                        <PartnerRow key={partner.id} partner={partner}/>
                                    )}
                                </Table> :
                                <SearchError query={search.searchQuery.query}/>
                            }
                        </Loadable>
                    </ContentBlock>
                </LayoutRows>
                <ModalCreatePartner
                    active={isShowModalCreatePartner}
                    onClose={() => setShowModalCreatePartner(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default Partners;
