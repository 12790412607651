import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    BoxProps,
    ButtonLink,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    Form,
    FormLayoutColumns,
    FormLayoutRows,
    FormLayoutSeparator,
    FormLayoutTitle,
    FormValidationType,
    InputNumber,
    InputText,
    InputTextNumber,
    LayoutColumns,
    LayoutRows,
    Loadable,
    Select,
    Table,
    ToggleOption,
    ToggleSwitch
} from "@sirdata/ui-lib";
import React, {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {FormLayoutMessage, MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {MainContentHeader, MainContentHeaderAction, PartnerPricings, PricingStepRow, SelectService, SelectStatus} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {Module} from "../../utils/Module";
import {Pricing} from "../../api/model/partner/pricing/Pricing";
import {detectChanges} from "../../common/utils/portal";
import {PricingField} from "../../api/model/partner/pricing/PricingField";
import {PRICING_BILLING_PERIODS, PricingBillingPeriod} from "../../api/model/partner/pricing/PricingBillingPeriod";
import {PRICING_STATUSES} from "../../api/model/partner/pricing/PricingStatus";
import {PricingStep} from "../../api/model/partner/pricing/PricingStep";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import {PricingStepField} from "../../api/model/partner/pricing/PricingStepField";
import {Status} from "../../utils/Status";
import {PRICING_DIVIDER_UNITS} from "../../api/model/partner/pricing/PricingDividerUnit";
import useAlert from "../../utils/hooks/useAlert";
import {Formatter} from "../../common/utils/Formatter";
import {Currency} from "../../api/model/currency/Currency";
import useFormValidator from "../../utils/hooks/useFormValidator";

function PricingsDetails() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textPricings} = useTranslation(TranslationPortalFile.PRICINGS);
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [isActiveDelete, setActiveDelete] = useState(false);

    const FORM_ID = "form-edit-pricing";
    const [pricing, setPricing] = useState<Pricing>(new Pricing());
    const [initPricing, setInitPricing] = useState<Pricing>(new Pricing());
    const {setErrors, setShowErrors, ...formValidator} = useFormValidator<PricingField>();

    useEffect(() => {
        (async () => {
            try {
                const pricing = await session.restPricing.get(+id);
                setPricing(pricing);
                setInitPricing(new Pricing(pricing));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("rate card", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(pricing, initPricing));
    }, [pricing, initPricing]);

    useEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            [PricingField.STEPS_PRICE]: pricing.step_price_is_per_unit && (!pricing.steps_price?.length || pricing.hasInvalidSteps())
        }));
    }, [setErrors, pricing]);

    const handleSaveCapture = () => {
        setShowErrors(true);
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        if (formValidator.hasErrors()) {
            return;
        }
        try {
            if (pricing.step_price_is_per_unit) {
                pricing.volume_cost_per_unit = 0;
                pricing.steps_price = pricing.steps_price.filter((it) => it.fee || it.volume_cost_per_unit);
            } else {
                pricing.steps_price = [];
            }

            await session.restPricing.update(pricing);
            setInitPricing(new Pricing(pricing));
            alert.updateWithSuccess("rate card");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("rate card", e.message);
            }
        } finally {
            setShowErrors(false);
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restPricing.delete(pricing.id);
            navigate(Module.PRICINGS.path);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("rate card", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleChange = (field: PricingField, value: any) => {
        setPricing((prevState) => new Pricing({...prevState, [field]: value}));
    };

    const handleAddPricingStep = () => {
        handleChange(PricingField.STEPS_PRICE, [...pricing.steps_price || [], new PricingStep()]);
    };

    const handleChangePricingStep = (index: number, step: PricingStep) => {
        let newPricingSteps = [...pricing.steps_price];
        newPricingSteps = newPricingSteps.map((it, idx) => (index === idx) ? step : it);
        handleChange(PricingField.STEPS_PRICE, newPricingSteps);
    };

    const handleDeletePricingStep = (stepIndex: number) => {
        let newPricingSteps = [...pricing.steps_price];
        newPricingSteps.splice(stepIndex, 1);
        handleChange(PricingField.STEPS_PRICE, newPricingSteps);
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.PRICINGS} element={initPricing.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <RestrictedContent allowedTo={Authorization.PRICINGS.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <LayoutRows>
                    <Form id={FORM_ID} onSubmitCapture={handleSaveCapture} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textPricings("section.information")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <FormLayoutColumns columns={4}>
                                                <FieldBlock label={textPricings(`field.${PricingField.STATUS}`)}>
                                                    <SelectStatus
                                                        value={pricing.status}
                                                        statuses={PRICING_STATUSES.filter((it) => it !== Status.DELETED)}
                                                        onChange={(status) => handleChange(PricingField.STATUS, status!.name)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_RIGHT}>
                                                <FieldBlock label={textPricings(`field.${PricingField.API_SERVICE}`)}>
                                                    <SelectService
                                                        value={pricing.api_service}
                                                        services={Pricing.apiServices()}
                                                        onChange={undefined}
                                                        disabled
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPricings(`field.${PricingField.NAME}`)} required>
                                                    <InputText
                                                        value={pricing.name}
                                                        onChange={(value) => handleChange(PricingField.NAME, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textPricings(`field.${PricingField.DESCRIPTION}`)}>
                                                <InputText
                                                    value={pricing.description}
                                                    onChange={(value) => handleChange(PricingField.DESCRIPTION, value)}
                                                />
                                            </FieldBlock>
                                            <ToggleSwitch
                                                label={textPricings(`field.${PricingField.DEFAULT}`)}
                                                name={PricingField.DEFAULT}
                                                checked={pricing.default}
                                                onChange={(value) => handleChange(PricingField.DEFAULT, value)}
                                            />
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textPricings("section.billing")}</FormLayoutTitle>
                                            <FieldBlock label={textPricings(`field.${PricingField.INVOICE_LABEL}`)}>
                                                <InputText
                                                    value={pricing.invoice_label}
                                                    onChange={(value) => handleChange(PricingField.INVOICE_LABEL, value)}
                                                />
                                            </FieldBlock>
                                            <FieldBlock
                                                label={textPricings(`field.${PricingField.INVOICE_DESCRIPTION}`)}
                                                tooltip={textPricings(`tooltip.${PricingField.INVOICE_DESCRIPTION}`)}
                                            >
                                                <InputText
                                                    value={pricing.invoice_description}
                                                    onChange={(value) => handleChange(PricingField.INVOICE_DESCRIPTION, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutColumns columns={2} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                <FormLayoutColumns layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}>
                                                    <FieldBlock label={textPricings(`field.${PricingField.BILLING_PERIOD}`)}>
                                                        <Select
                                                            value={pricing.billing_period}
                                                            options={PRICING_BILLING_PERIODS.map((it) => ({value: it, label: textPricings(`billing_period.${it}`)}))}
                                                            onChange={(option) => handleChange(PricingField.BILLING_PERIOD, option?.value as PricingBillingPeriod)}
                                                        />
                                                    </FieldBlock>
                                                    <FieldBlock label={textPricings(`field.${PricingField.BILLING_DAY}`)}>
                                                        <InputNumber
                                                            value={pricing.billing_day}
                                                            min={1}
                                                            max={31}
                                                            disabled={pricing.billing_period !== PricingBillingPeriod.MONTH}
                                                            onChange={(value) => handleChange(PricingField.BILLING_DAY, value)}
                                                        />
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            </FormLayoutColumns>
                                            <FieldBlock label={textPricings(`field.${PricingField.BARTER_AGREEMENT}`)} name={PricingField.BARTER_AGREEMENT}>
                                                <ToggleSwitch
                                                    label={textPricings(`field.${PricingField.BARTER_AGREEMENT}_text`)}
                                                    checked={pricing.barter_agreement}
                                                    onChange={(value) => handleChange(PricingField.BARTER_AGREEMENT, value)}
                                                />
                                            </FieldBlock>
                                            <FormLayoutSeparator/>
                                            <FormLayoutColumns columns={3}>
                                                <FieldBlock label={textPricings(`field.${PricingField.AXONAUT_PRODUCT_ID}`)}>
                                                    <InputNumber
                                                        value={pricing.axonaut_product_id}
                                                        onChange={(value) => handleChange(PricingField.AXONAUT_PRODUCT_ID, value)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <ContentBlock header={{title: {label: textPricings("section.settings")}}}>
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                        <FormLayoutRows>
                                            <Alert text={textPricings("message.prices_mention")}/>
                                            <FormLayoutColumns columns={3}>
                                                <FieldBlock
                                                    label={textPricings(`field.${PricingField.FEE}`)}
                                                    tooltip={textPricings(`tooltip.${PricingField.FEE}`)}
                                                >
                                                    <InputTextNumber
                                                        value={Formatter.convertCentsToUnits(pricing.fee)}
                                                        min={0}
                                                        onChange={(value) => handleChange(PricingField.FEE, Formatter.convertUnitsToCents(value))}
                                                    />
                                                    <span>{Currency.EUR.symbol}</span>
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            <FormLayoutSeparator/>
                                            <FormLayoutTitle>{textPricings("section.prices_on_volume")}</FormLayoutTitle>
                                            <FormLayoutColumns>
                                                <FieldBlock label={textPricings("field.volume_price_type.title")}>
                                                    <ToggleOption
                                                        firstOption={textPricings(`field.volume_price_type.${PricingField.VOLUME_COST_PER_UNIT}`)}
                                                        secondOption={textPricings(`field.volume_price_type.${PricingField.STEP_PRICE_IS_PER_UNIT}`)}
                                                        isFirstOption={!pricing.step_price_is_per_unit}
                                                        onClick={(value) => handleChange(PricingField.STEP_PRICE_IS_PER_UNIT, !value)}
                                                    />
                                                </FieldBlock>
                                                <FieldBlock label={textPricings("field.divider_unit")}>
                                                    <Select
                                                        value={pricing.divider_unit}
                                                        options={PRICING_DIVIDER_UNITS.map((it) => ({value: it.value, label: textPricings(`divider_unit.${it.name}`)}))}
                                                        onChange={(option) => handleChange(PricingField.DIVIDER_UNIT, option ? +option?.value : 0)}
                                                    />
                                                </FieldBlock>
                                            </FormLayoutColumns>
                                            {pricing.step_price_is_per_unit ?
                                                <>
                                                    <ToggleSwitch
                                                        name={PricingField.ONLY_LAST_STEP_PRICE_BILLABLE}
                                                        label={textPricings(`field.${PricingField.ONLY_LAST_STEP_PRICE_BILLABLE}`)}
                                                        checked={pricing.only_last_step_price_billable}
                                                        onChange={(value) => handleChange(PricingField.ONLY_LAST_STEP_PRICE_BILLABLE, value)}
                                                    />
                                                    <FieldBlock label={textPricings("field.volume_price_type.price_levels")} content={{direction: FlexContentDirection.COLUMN}}>
                                                        <Table
                                                            columns={[
                                                                {width: 30, label: textPricings(`field.step.${PricingStepField.LIMIT}`)},
                                                                {width: 30, label: textPricings(`field.step.${PricingStepField.FEE}`)},
                                                                {width: 30, label: textPricings(`field.step.${PricingStepField.VOLUME_COST_PER_UNIT}`)},
                                                                {width: 10}
                                                            ]}
                                                        >
                                                            {pricing.steps_price.map((step, index) =>
                                                                <PricingStepRow
                                                                    key={`pricing-step-row-${index.toString()}`}
                                                                    step={step}
                                                                    onChange={(step) => handleChangePricingStep(index, step)}
                                                                    onRemove={() => handleDeletePricingStep(index)}
                                                                />
                                                            )}
                                                        </Table>
                                                        {formValidator.isError(PricingField.STEPS_PRICE) &&
                                                            <FormLayoutMessage message={pricing.hasInvalidSteps() ? textPricings("message.invalid_pricing_steps") : t("message.error.field_required")} severity={AlertSeverity.DANGER} small/>
                                                        }
                                                        {(!pricing.steps_price.length || !!pricing.steps_price[pricing.steps_price.length - 1].limit) &&
                                                            <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.END}>
                                                                <ButtonLink
                                                                    icon={{name: "add_circle"}}
                                                                    onClick={handleAddPricingStep}
                                                                    reverseUnderline
                                                                >
                                                                    {textPricings("add_step")}
                                                                </ButtonLink>
                                                            </FlexContent>
                                                        }
                                                    </FieldBlock>
                                                </> :
                                                <FormLayoutColumns columns={3}>
                                                    <FieldBlock label={textPricings("field.volume_cost_per_unit")}>
                                                        <InputTextNumber
                                                            value={Formatter.convertCentsToUnits(pricing.volume_cost_per_unit)}
                                                            min={0}
                                                            onChange={(value) => handleChange(PricingField.VOLUME_COST_PER_UNIT, Formatter.convertUnitsToCents(value))}
                                                        />
                                                        <span>{Currency.EUR.symbol}</span>
                                                    </FieldBlock>
                                                </FormLayoutColumns>
                                            }
                                        </FormLayoutRows>
                                    </Box>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                    </Form>
                    <Loadable loading={isLoading}>
                        <PartnerPricings pricingId={pricing.id}/>
                    </Loadable>
                </LayoutRows>
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="rate card"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default PricingsDetails;
