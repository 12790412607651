import {Action, ContentBlock, ContentBlockAction, Form, FormValidationType, LayoutColumns, LayoutRows, Loadable, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {FormEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {Authorization} from "../../api/model/account/Authorization";
import {SgtmContainer} from "../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerField} from "../../api/model/sgtm/container/SgtmContainerField";
import {SgtmContainerHostDetailsField} from "../../api/model/sgtm/container/SgtmContainerHostDetailsField";
import {SgtmContainerProperties} from "../../api/model/sgtm/container/SgtmContainerProperties";
import {SgtmContainerPropertiesField} from "../../api/model/sgtm/container/SgtmContainerPropertiesField";
import {SirdataApiEvent} from "../../common/api/CommonApiClient";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {detectChanges} from "../../common/utils/portal";
import ModalConfirmDelete from "../../component/modal/ModalConfirmDelete";
import ModalConfirmRemove from "../../component/modal/ModalConfirmRemove";
import ModalAddSgtmContainerHost from "../../component/modal/sgtm-containers/ModalAddSgtmContainerHost";
import {MainContentHeader, MainContentHeaderAction, SgtmContainerAdvancedConfiguration, SgtmContainerHostRow, SgtmContainerInformation} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import useAlert from "../../utils/hooks/useAlert";
import {Module} from "../../utils/Module";

function SgtmContainersDetails() {
    const alert = useAlert();
    const navigate = useNavigate();
    const {id} = useParams() as {id: string};
    const {t: textSgtmContainers} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS);
    const [isLoading, setLoading] = useState(true);
    const [isActiveDelete, setActiveDelete] = useState(false);
    const [isShowModalAddSgtmContainerHost, setShowModalAddSgtmContainerHost] = useState(false);
    const [isUnsavedChanges, setUnsavedChanges] = useState(false);
    const [activeRemoveHost, setActiveRemoveHost] = useState<string>();

    const FORM_ID = "form-edit-sgtm-container";
    const [container, setContainer] = useState<SgtmContainer>(new SgtmContainer());
    const [initContainer, setInitContainer] = useState<SgtmContainer>(new SgtmContainer());

    useEffect(() => {
        (async () => {
            try {
                const container = await session.restSgtmContainer.get(+id);
                setContainer(container);
                setInitContainer(new SgtmContainer(container));
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    if (e.statusCode === HttpStatusCode.NOT_FOUND) {
                        session.emit(SirdataApiEvent.eventNotFound);
                    } else {
                        alert.failToLoad("container", e.message);
                    }
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [id, alert]);

    useEffect(() => {
        setUnsavedChanges(detectChanges(container, initContainer));
    }, [container, initContainer]);

    const handleChange = (field: SgtmContainerField, value: any) => {
        setContainer((prevState) => new SgtmContainer({...prevState, [field]: value}));
    };

    const handleChangeDefaultProperties = (fields: { [key in SgtmContainerPropertiesField]?: any }) => {
        handleChange(SgtmContainerField.DEFAULT_PROPERTIES, new SgtmContainerProperties({...container.default_properties, ...fields}));
    };

    const handleSave = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const newContainer = await session.restSgtmContainer.update(container);
            setContainer(newContainer);
            setInitContainer(new SgtmContainer(newContainer));
            alert.updateWithSuccess("container");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("container", e.message);
            }
        }
    };

    const handleDelete = async () => {
        if (!isActiveDelete) return;
        try {
            await session.restSgtmContainer.delete(container.id);
            navigate(Module.SGTM_CONTAINERS.path);
            alert.deleteWithSuccess("container");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToDelete("container", e.message);
            }
        } finally {
            setActiveDelete(false);
        }
    };

    const handleAddHost = (updatedContainer: SgtmContainer) => {
        container.host_details = updatedContainer.host_details;
        container.instance_hosts = updatedContainer.instance_hosts;
        setContainer(container);
        setShowModalAddSgtmContainerHost(false);
    };

    const handleRemoveHost = async () => {
        initContainer.instance_hosts = initContainer.instance_hosts.filter((it) => it !== activeRemoveHost);
        try {
            const updatedContainer = await session.restSgtmContainer.update(initContainer);
            container.host_details = updatedContainer.host_details;
            container.instance_hosts = updatedContainer.instance_hosts;
            setContainer(container);
            setActiveRemoveHost(undefined);
            alert.updateWithSuccess("container hosts");
        } catch (e) {
            if (e instanceof ErrorResponse) {
                alert.failToUpdate("container hosts", e.message);
            }
        }
    };

    return (
        <Wrapper>
            <MainHeader preventUnsaved={isUnsavedChanges}/>
            <MainContentHeader module={Module.SGTM_CONTAINERS} element={container.toContentElement()} preventUnsaved={isUnsavedChanges}>
                <MainContentHeaderAction action={new Action(textSgtmContainers("action.analytics"), {name: "bar_chart"})} onClick={() => navigate(container.getRouteAnalytics())}/>
                <RestrictedContent allowedTo={Authorization.SGTM.update}>
                    <MainContentHeaderAction action={Action.SAVE} form={FORM_ID} disabled={!isUnsavedChanges}/>
                    <MainContentHeaderAction action={Action.DELETE} onClick={() => setActiveDelete(true)}/>
                </RestrictedContent>
            </MainContentHeader>
            <MainContent>
                <Form id={FORM_ID} onSubmit={handleSave} validationType={FormValidationType.CUSTOM}>
                    <LayoutRows>
                        <LayoutColumns>
                            <Loadable loading={isLoading}>
                                <SgtmContainerInformation container={container} onChange={handleChange}/>
                            </Loadable>
                            <Loadable loading={isLoading}>
                                <ContentBlock
                                    header={{
                                        title: {label: textSgtmContainers("section.hosts")},
                                        actions: (
                                            <RestrictedContent allowedTo={Authorization.SGTM.update}>
                                                <ContentBlockAction
                                                    action={Action.ADD}
                                                    onClick={() => setShowModalAddSgtmContainerHost(true)}
                                                />
                                            </RestrictedContent>
                                        )
                                    }}
                                >
                                    <Table
                                        columns={[
                                            {width: 20, label: textSgtmContainers(`field.${SgtmContainerField.HOST_DETAILS}.${SgtmContainerHostDetailsField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                                            {width: 20, label: textSgtmContainers(`field.${SgtmContainerField.HOST_DETAILS}.${SgtmContainerHostDetailsField.SUBDOMAIN}`)},
                                            {width: 55, label: textSgtmContainers(`field.${SgtmContainerField.HOST_DETAILS}.${SgtmContainerHostDetailsField.FQDN}`)},
                                            {width: 5}
                                        ]}
                                    >
                                        {container.host_details.map((host) =>
                                            <SgtmContainerHostRow
                                                key={host.fqdn}
                                                host={host}
                                                onRemove={container.host_details.length > 1 ? () => setActiveRemoveHost(host.fqdn) : undefined}
                                            />
                                        )}
                                    </Table>
                                </ContentBlock>
                            </Loadable>
                        </LayoutColumns>
                        <Loadable loading={isLoading}>
                            <SgtmContainerAdvancedConfiguration container={container} onChange={handleChangeDefaultProperties}/>
                        </Loadable>
                    </LayoutRows>
                </Form>
                <ModalAddSgtmContainerHost
                    active={isShowModalAddSgtmContainerHost}
                    initContainer={initContainer}
                    onSubmit={handleAddHost}
                    onClose={() => setShowModalAddSgtmContainerHost(false)}
                />
                <ModalConfirmDelete
                    active={isActiveDelete}
                    entity="container"
                    confirm={handleDelete}
                    cancel={() => setActiveDelete(false)}
                />
                <ModalConfirmRemove
                    active={!!activeRemoveHost}
                    entity={`host "${activeRemoveHost}"`}
                    confirm={handleRemoveHost}
                    cancel={() => setActiveRemoveHost(undefined)}
                />
            </MainContent>
        </Wrapper>
    );
}

export default SgtmContainersDetails;
