import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {Formatter} from "../../../../../common/utils/Formatter";
import {SgtmContainerHitStatPeriod} from "./SgtmContainerHitStatPeriod";
import moment from "moment/moment";

export class SgtmContainerHitStatRequest extends ApiModel {
    partner_id: number = 0;
    config_id?: number;
    start_date: string = SgtmContainerHitStatPeriod.LAST_30_DAYS.interval.start;
    end_date: string = SgtmContainerHitStatPeriod.LAST_30_DAYS.interval.end;
    period: SgtmContainerHitStatPeriod = SgtmContainerHitStatPeriod.LAST_30_DAYS;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            config_id: this.config_id,
            start_date: Formatter.formatDate(this.start_date, Formatter.API_DATE_FORMAT),
            end_date: Formatter.formatDate(this.end_date, Formatter.API_DATE_FORMAT)
        };
    }

    withPeriod(period: SgtmContainerHitStatPeriod): SgtmContainerHitStatRequest {
        this.period = period;
        this.start_date = period.interval.start;
        this.end_date = period.interval.end;
        return this;
    }

    isInvalid(): boolean {
        if (!this.partner_id || !this.start_date || !this.end_date) return true;
        if (moment(this.start_date).diff(moment(), "day") > 0) return true;
        return moment(this.start_date).diff(this.end_date, "day") > 0;
    }
}
