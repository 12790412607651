import {ApiModel} from "../../../common/api/model/ApiModel";
import {CountryCode} from "../../../common/api/model/Country";
import {CountryIsoCode} from "../../../common/api/model/CountryIsoCode";
import {Status} from "../../../utils/Status";
import {PartnerBillingInfoField} from "./PartnerBillingInfoField";
import {PartnerBillingVatType} from "./PartnerBillingVatType";
import {PartnerBillingPaymentMethod} from "./PartnerBillingPaymentMethod";
import {PartnerPaymentMethod} from "./PartnerPaymentMethod";

export class PartnerBillingInfo extends ApiModel {
    partner_id: number = 0;
    company_name: string = "";
    contact_lastname: string = "";
    contact_firstname: string = "";
    address: string = "";
    postal_code: string = "";
    city: string = "";
    country_iso: CountryIsoCode = CountryCode.FRANCE.code;
    vat: string = "";
    vat_type: string = PartnerBillingVatType.FR;
    business_id: string = "";
    payment_method: string = PartnerBillingPaymentMethod.MONTHLY.name;
    small_business: boolean = false;
    axonaut_id?: number;
    readonly payment_methods?: PartnerPaymentMethod[];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObjects(PartnerBillingInfoField.PAYMENT_METHODS, PartnerPaymentMethod);
    }

    getValidPaymentMethod(): PartnerPaymentMethod | undefined {
        return (this.payment_methods || [])
            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
            .find((it) => it.status.toUpperCase() === Status.VALIDATED.name);
    }

    getJsonParameters(): {} {
        return {
            partner_id: this.partner_id,
            company_name: this.company_name,
            contact_lastname: this.contact_lastname,
            contact_firstname: this.contact_firstname,
            address: this.address,
            postal_code: this.postal_code,
            city: this.city,
            country_iso: this.country_iso,
            vat: this.vat,
            business_id: this.business_id,
            payment_method: this.payment_method,
            small_business: this.small_business,
            axonaut_id: this.axonaut_id
        };
    }
}

