import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {SgtmContainerHitStatPerConf} from "./SgtmContainerHitStatPerConf";
import {SgtmContainerHitStatField} from "./SgtmContainerHitStatField";

export class SgtmContainerHitStat extends ApiModel {
    date: string = "";
    total_hits: number = 0;
    hit_per_conf: SgtmContainerHitStatPerConf[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObjects(SgtmContainerHitStatField.HIT_PER_CONF, SgtmContainerHitStatPerConf);
    }
}
