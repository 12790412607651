import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpConsentStatRates} from "./CmpConsentStatRates";

export class CmpConsentStatDetails extends ApiModel {
    dates: string[] = [];
    global: CmpConsentStatRates = new CmpConsentStatRates();
    desktop: CmpConsentStatRates = new CmpConsentStatRates();
    mobile: CmpConsentStatRates = new CmpConsentStatRates();
    tablet: CmpConsentStatRates = new CmpConsentStatRates();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObject("global", CmpConsentStatRates);
        this.loadObject("desktop", CmpConsentStatRates);
        this.loadObject("mobile", CmpConsentStatRates);
        this.loadObject("tablet", CmpConsentStatRates);
    }
}
