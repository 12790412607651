import {ApiModel} from "../../../../../common/api/model/ApiModel";

export class SgtmContainerHitStatPerConfPerType extends ApiModel {
    FORWARD: number = 0;
    ANALYTICS: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
