import {Rest} from "../../common/api/rest/Rest";
import {RestService} from "../interface/RestService";
import {HelperDomainSearchQuery} from "../model/helper/domain/HelperDomainSearchQuery";
import {HelperDomainSearchResult} from "../model/helper/domain/HelperDomainSearchResult";
import {HelperDomain} from "../model/helper/domain/HelperDomain";

export class RestHelperDomain extends Rest implements RestService<HelperDomain> {
    rootPath = "/console-api/helper/domain";

    search(params: HelperDomainSearchQuery): Promise<HelperDomainSearchResult> {
        return this._client.get(`${this.rootPath}`, HelperDomainSearchResult, {queryParams: params.getJsonParameters()}) as Promise<HelperDomainSearchResult>;
    }

    list(partnerId: number): Promise<HelperDomain[]> {
        return this._client.get(`${this.rootPath}/list`, HelperDomain, {queryParams: {partner_id: partnerId}}) as Promise<HelperDomain[]>;
    }

    create(domain: HelperDomain): Promise<HelperDomain> {
        return new Promise<HelperDomain>(() => {}); // TODO Implement route
    }

    get(domainId: string): Promise<HelperDomain> {
        return this._client.get(`${this.rootPath}/${domainId}`, HelperDomain) as Promise<HelperDomain>;
    }

    update(domain: HelperDomain): Promise<HelperDomain> {
        return this._client.put(`${this.rootPath}/${domain.id}`, domain, HelperDomain) as Promise<HelperDomain>;
    }

    delete(domainId: string): Promise<HelperDomain> {
        return this._client.delete(`${this.rootPath}/${domainId}`) as Promise<HelperDomain>;
    }
}
