import moment from "moment";
import {CmpStatPeriodInterval} from "./CmpStatPeriodInterval";

export class CmpStatPeriod {
    static LAST_7_DAYS: CmpStatPeriod = new CmpStatPeriod(
        "LAST_7_DAYS",
        new CmpStatPeriodInterval(moment().subtract(7, "day"), moment().subtract(1, "days")),
        new CmpStatPeriodInterval(moment().subtract(14, "days"), moment().subtract(8, "days"))
    );
    static LAST_30_DAYS: CmpStatPeriod = new CmpStatPeriod(
        "LAST_30_DAYS",
        new CmpStatPeriodInterval(moment().subtract(30, "day"), moment().subtract(1, "days")),
        new CmpStatPeriodInterval(moment().subtract(60, "days"), moment().subtract(31, "days"))
    );
    static MONTH: CmpStatPeriod = new CmpStatPeriod(
        "MONTH",
        new CmpStatPeriodInterval(moment().startOf("month"), moment().endOf("month")),
        new CmpStatPeriodInterval(moment().subtract(1, "months").startOf("month"), moment().subtract(1, "months").endOf("month"))
    );
    static LAST_MONTH: CmpStatPeriod = new CmpStatPeriod(
        "LAST_MONTH",
        new CmpStatPeriodInterval(moment().subtract(1, "months").startOf("month"), moment().subtract(1, "months").endOf("month")),
        new CmpStatPeriodInterval(moment().subtract(2, "months").startOf("month"), moment().subtract(2, "months").endOf("month"))
    );
    static LAST_3_MONTHS: CmpStatPeriod = new CmpStatPeriod(
        "LAST_3_MONTHS",
        new CmpStatPeriodInterval(moment().subtract(2, "months").startOf("month"), moment().endOf("month")),
        new CmpStatPeriodInterval(moment().subtract(5, "months").startOf("month"), moment().subtract(3, "months").endOf("month"))
    );
    static YEAR: CmpStatPeriod = new CmpStatPeriod(
        "YEAR",
        new CmpStatPeriodInterval(moment().startOf("year"), moment().endOf("year")),
        new CmpStatPeriodInterval(moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year"))
    );
    static CUSTOM: CmpStatPeriod = new CmpStatPeriod(
        "CUSTOM",
        new CmpStatPeriodInterval(moment().subtract(1, "days"), moment().subtract(1, "days"))
    );

    name: string;
    interval: CmpStatPeriodInterval;
    previousInterval?: CmpStatPeriodInterval;

    private constructor(name: string, interval: CmpStatPeriodInterval, previousInterval?: CmpStatPeriodInterval) {
        this.name = name;
        this.interval = interval;
        this.previousInterval = previousInterval;
    }

    static values(): CmpStatPeriod[] {
        return [
            CmpStatPeriod.LAST_7_DAYS,
            CmpStatPeriod.LAST_30_DAYS,
            CmpStatPeriod.MONTH,
            CmpStatPeriod.LAST_MONTH,
            CmpStatPeriod.LAST_3_MONTHS,
            CmpStatPeriod.YEAR,
            CmpStatPeriod.CUSTOM
        ];
    };

    static getByName(name: string): CmpStatPeriod | undefined {
        return this.values().find((it) => it.name === name);
    }
}
