import {FC, useEffect, useState} from "react";
import {Action, ContentBlock, IconTooltip, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {LabelStatus} from "../index";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {HelperDomain} from "../../../api/model/helper/domain/HelperDomain";
import {HelperDomainField} from "../../../api/model/helper/domain/HelperDomainField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";

type PartnerHelperDomainsProps = {
    partnerId: number;
}

const PartnerHelperDomains: FC<PartnerHelperDomainsProps> = ({partnerId}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textHelperDomains} = useTranslation(TranslationPortalFile.HELPER_DOMAINS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [domains, setDomains] = useState<HelperDomain[]>([]);

    useEffect(() => {
        if (!partnerId) return;
        (async () => {
            try {
                const domains = await session.restHelperDomain.list(partnerId);
                setDomains(domains);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("Helper domains", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, partnerId]);

    return (
        <Loadable loading={isLoading}>
            <ContentBlock>
                <Table
                    columns={[
                        {width: 5, label: textHelperDomains(`field.${HelperDomainField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textHelperDomains(`field.${HelperDomainField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 40, label: textHelperDomains(`field.${HelperDomainField.NAME}`)},
                        {width: 40, label: textHelperDomains(`field.${HelperDomainField.DOMAIN}`)},
                        {width: 5}
                    ]}
                    messageIfEmpty={textHelperDomains("message.no_helper_domain_for_partner")}
                >
                    {domains.map((domain) =>
                        <TableRow key={domain.id}>
                            <TableColumn>
                                <LabelStatus status={domain.getStatus()}/>
                            </TableColumn>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{domain.id}</TableColumn>
                            <TableColumn>{domain.name}</TableColumn>
                            <TableColumn>{domain.domain}</TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <RestrictedContent allowedTo={Authorization.HELPER_DOMAINS.name}>
                                    <IconTooltip
                                        icon={Action.OPEN.icon}
                                        text={textCommon(Action.OPEN.labelKey)}
                                        onClick={() => window.open(domain.getRoute(), "_blank")}
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
            </ContentBlock>
        </Loadable>
    );
};

export default PartnerHelperDomains;
