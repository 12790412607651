import {PAGE_SIZE} from "../../../utils/constants";
import {SearchParams} from "../../../utils/SearchParams";
import {SearchParamsField} from "../../../utils/SearchParamsField";
import {Status} from "../../../utils/Status";
import {SearchQuery} from "../../interface/SearchQuery";
import {PartnerField} from "./PartnerField";

export enum PartnerSearchParamsField {
    SERVICES = "services",
    IS_LIVE = "is_live",
    ALLOWS_SIRDATA_ACCESS = "allows_sirdata_access"
}

export class PartnerSearchQuery extends SearchQuery {
    size: number = PAGE_SIZE;
    status?: string;
    type?: string;
    services?: string;
    owner?: number;
    is_live?: boolean;
    allows_sirdata_access?: boolean;

    withSearchParams(params: SearchParams): PartnerSearchQuery {
        let newQuery = super.withSearchParams(params).withDefaultSorting(PartnerField.CREATED_AT, true) as PartnerSearchQuery;
        newQuery.status = params.getString(SearchParamsField.STATUS);
        newQuery.type = params.getString(SearchParamsField.TYPE);
        newQuery.services = params.getString(PartnerSearchParamsField.SERVICES);
        newQuery.owner = params.getNumber(SearchParamsField.OWNER);
        newQuery.is_live = params.getBoolean(PartnerSearchParamsField.IS_LIVE);
        newQuery.allows_sirdata_access = params.getBoolean(PartnerSearchParamsField.ALLOWS_SIRDATA_ACCESS);
        return newQuery;
    }

    getJsonParameters(): {} {
        return {
            query: this.query,
            sort: this.sortBy,
            reverse: this.reverseOrder,
            page: this.page,
            size: this.size,
            active: !!this.status ? `${this.status === Status.ACTIVE.name}` : undefined,
            type: this.type,
            services: this.services,
            owner: this.owner,
            is_live: this.is_live !== undefined ? `${this.is_live}` : undefined,
            allows_sirdata_access: this.allows_sirdata_access !== undefined ? `${this.allows_sirdata_access}` : undefined
        };
    }
}
