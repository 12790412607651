import {Box, BoxRadius, ContentBlock} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

type ChangelogItem = {
    date: string;
    items: string[];
}

const CHANGELOG_ITEMS: ChangelogItem[] = [
    {
        date: "01/04/2025",
        items: [
            "CMP Configurations : Ajout de la possibilité de filtrer les configurations en cours",
            "Monthly Revenues : Correction d'un bug empêchant la saisie des montants avec décimales",
            "sGTM Containers : Ajout des hits dans le détail d'un container"
        ]
    },
    {
        date: "24/03/2025",
        items: [
            "Buyers Seats : Correction d'un bug empêchant la sauvegarde d'un seat",
            "CMP Configurations : Ajout des statistiques",
            "Partners : Amélioration de l'affichage des produits utilisés par les partenaires",
            "Partners : Ajout d'un filtre 'Sirdata access'",
            "Partners : Affichage de la liste des configurations ABconsent, Helper et sGTM de chaque partenaire",
            "sGTM Containers : Ajout des paramétrages First-id et SHA-256"
        ]
    },
    {
        date: "18/03/2025",
        items: [
            "Categories : Le champ prix en euros est désormais obligatoire",
            "Monthly Revenues : Ajout des informations sur les totals de revenus",
            "Partners : Mise à jour des informations de paiement",
            "Partners : Correction d'un bug sur le tri des partenaires"
        ]
    }
];

const Changelog: FunctionComponent = () => {
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);

    return (
        <ContentBlock header={{title: {label: textHome("changelog.title").toUpperCase()}}} cssClass={"changelog"}>
            <Box radius={BoxRadius.LG} cssClass="changelog__content">
                {CHANGELOG_ITEMS.map((item) =>
                    <div key={item.date} className="changelog__item">
                        <div className="changelog__item__date">{item.date}</div>
                        <ul className="changelog__item__content">
                            {item.items.map((it) =>
                                <li key={it} dangerouslySetInnerHTML={{__html: it}}/>
                            )}
                        </ul>
                    </div>
                )}
            </Box>
        </ContentBlock>
    );
};

export default Changelog;
