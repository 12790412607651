import React from "react";

type ChartContainerProps = {
    children: React.ReactNode;
    height?: string;
    width?: string;
    cssClass?: string;
};

const ChartContainer: React.FC<ChartContainerProps> = ({children, height, width, cssClass}) => {
    return (
        <div
            className={cssClass}
            style={{position: "relative", height, width}}
        >
            {children}
        </div>
    );
};

export default ChartContainer;
