import {ChartOptions} from "chart.js";
import React from "react";
import {Line} from "react-chartjs-2";
import {chartLineOptions, GraphData} from "../../../utils/cmp/CmpChartOptions";

interface ChartLineProps {
    data: GraphData | undefined;
    options?: ChartOptions<"line">;
}

const ChartLine: React.FC<ChartLineProps> = ({data, options = chartLineOptions}) => {
    if (!data) return <></>;

    return <Line data={data} options={options}/>;
};

export default ChartLine;
