import {FC, useEffect, useState} from "react";
import {Action, ContentBlock, IconTooltip, Loadable, Table, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TagStyle, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {Authorization} from "../../../api/model/account/Authorization";
import {SgtmContainer} from "../../../api/model/sgtm/container/SgtmContainer";
import {SgtmContainerField} from "../../../api/model/sgtm/container/SgtmContainerField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {RestrictedContent} from "../../../common/component/widget";
import {TranslationPortalFile} from "../../../utils/constants";
import useAlert from "../../../utils/hooks/useAlert";
import {LabelStatus, Tag} from "../index";

type PartnerSgtmContainersProps = {
    partnerId: number;
}

const PartnerSgtmContainers: FC<PartnerSgtmContainersProps> = ({partnerId}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textSgtmContainer} = useTranslation(TranslationPortalFile.SGTM_CONTAINERS);
    const alert = useAlert();
    const [isLoading, setLoading] = useState(true);
    const [containers, setContainers] = useState<SgtmContainer[]>([]);

    useEffect(() => {
        if (!partnerId) return;
        (async () => {
            try {
                const containers = await session.restSgtmContainer.list(partnerId);
                setContainers(containers);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    alert.failToLoad("sGTM containers", e.message);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [alert, partnerId]);

    return (
        <Loadable loading={isLoading}>
            <ContentBlock>
                <Table
                    columns={[
                        {width: 5, label: textSgtmContainer(`field.${SgtmContainerField.STATUS}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 10, label: textSgtmContainer(`field.${SgtmContainerField.ID}`), styles: TableColumnStyle.ALIGN_CENTER},
                        {width: 40, label: textSgtmContainer(`field.${SgtmContainerField.NAME}`)},
                        {width: 40, label: textSgtmContainer(`field.${SgtmContainerField.INSTANCE_HOSTS}`)},
                        {width: 5}
                    ]}
                    messageIfEmpty={textSgtmContainer("message.no_sgtm_container_for_partner")}
                >
                    {containers.map((container) =>
                        <TableRow key={container.id}>
                            <TableColumn>
                                <LabelStatus status={container.getStatus()}/>
                            </TableColumn>
                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{container.id}</TableColumn>
                            <TableColumn>{container.name}</TableColumn>
                            <TableColumn>
                                {container.instance_hosts.map((host) =>
                                    <Tag key={host} label={host} style={TagStyle.DEFAULT_OCEAN}/>
                                )}
                            </TableColumn>
                            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                                <RestrictedContent allowedTo={Authorization.SGTM.name}>
                                    <IconTooltip
                                        icon={Action.OPEN.icon}
                                        text={textCommon(Action.OPEN.labelKey)}
                                        onClick={() => window.open(container.getRoute(), "_blank")}
                                    />
                                </RestrictedContent>
                            </TableActionColumn>
                        </TableRow>
                    )}
                </Table>
            </ContentBlock>
        </Loadable>
    );
};

export default PartnerSgtmContainers;
