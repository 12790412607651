import {ApiModel} from "../../../../common/api/model/ApiModel";
import {SalesRevenueFiltersField} from "./SalesRevenueFiltersField";
import {User} from "../../user/User";
import {SalesRevenueProduct} from "./SalesRevenueProduct";
import {PartnerOrganization} from "../../partner/organization/PartnerOrganization";
import {RevenueSeat} from "../seat/RevenueSeat";

export class SalesRevenueFilters extends ApiModel {
    seats: RevenueSeat[] = [];
    organizations: PartnerOrganization[] = [];
    owners: User[] = [];
    platforms: string[] = [];
    products: SalesRevenueProduct[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) {
            return;
        }
        super.load(o);
        this.loadObjects(SalesRevenueFiltersField.SEATS, RevenueSeat);
        this.loadObjects(SalesRevenueFiltersField.ORGANIZATIONS, PartnerOrganization);
        this.loadObjects(SalesRevenueFiltersField.OWNERS, User);
        this.loadObjects(SalesRevenueFiltersField.PRODUCTS, SalesRevenueProduct);
    }
}
