import {SgtmContainerHitStatPeriodInterval} from "./SgtmContainerHitStatPeriodInterval";
import {Formatter} from "../../../../../common/utils/Formatter";
import moment from "moment/moment";

export class SgtmContainerHitStatPeriod {
    static LAST_7_DAYS: SgtmContainerHitStatPeriod = new SgtmContainerHitStatPeriod(
        "LAST_7_DAYS",
        new SgtmContainerHitStatPeriodInterval(
            Formatter.formatDate(moment().subtract(7, "day"), Formatter.API_DATE_FORMAT),
            Formatter.formatDate(moment(), Formatter.API_DATE_FORMAT)
        )
    );
    static LAST_30_DAYS: SgtmContainerHitStatPeriod = new SgtmContainerHitStatPeriod(
        "LAST_30_DAYS",
        new SgtmContainerHitStatPeriodInterval(
            Formatter.formatDate(moment().subtract(30, "day"), Formatter.API_DATE_FORMAT),
            Formatter.formatDate(moment(), Formatter.API_DATE_FORMAT)
        )
    );
    static MONTH: SgtmContainerHitStatPeriod = new SgtmContainerHitStatPeriod(
        "MONTH",
        new SgtmContainerHitStatPeriodInterval(
            Formatter.formatDate(moment().startOf("month"), Formatter.API_DATE_FORMAT),
            Formatter.formatDate(moment().endOf("month"), Formatter.API_DATE_FORMAT)
        )
    );
    static LAST_MONTH: SgtmContainerHitStatPeriod = new SgtmContainerHitStatPeriod(
        "LAST_MONTH",
        new SgtmContainerHitStatPeriodInterval(
            Formatter.formatDate(moment().subtract(1, "months").startOf("month"), Formatter.API_DATE_FORMAT),
            Formatter.formatDate(moment().subtract(1, "months").endOf("month"), Formatter.API_DATE_FORMAT)
        )
    );
    static LAST_3_MONTHS: SgtmContainerHitStatPeriod = new SgtmContainerHitStatPeriod(
        "LAST_3_MONTHS",
        new SgtmContainerHitStatPeriodInterval(
            Formatter.formatDate(moment().subtract(2, "months").startOf("month"), Formatter.API_DATE_FORMAT),
            Formatter.formatDate(moment().endOf("month"), Formatter.API_DATE_FORMAT)
        )
    );
    static YEAR: SgtmContainerHitStatPeriod = new SgtmContainerHitStatPeriod(
        "YEAR",
        new SgtmContainerHitStatPeriodInterval(
            Formatter.formatDate(moment().startOf("year"), Formatter.API_DATE_FORMAT),
            Formatter.formatDate(moment().endOf("year"), Formatter.API_DATE_FORMAT)
        )
    );
    static CUSTOM: SgtmContainerHitStatPeriod = new SgtmContainerHitStatPeriod(
        "CUSTOM",
        new SgtmContainerHitStatPeriodInterval(
            Formatter.formatDate(moment().subtract(1, "day"), Formatter.API_DATE_FORMAT)
        )
    );

    name: string;
    interval: SgtmContainerHitStatPeriodInterval;

    private constructor(name: string, interval: SgtmContainerHitStatPeriodInterval) {
        this.name = name;
        this.interval = interval;
    }

    static values(): SgtmContainerHitStatPeriod[] {
        return [
            SgtmContainerHitStatPeriod.LAST_7_DAYS,
            SgtmContainerHitStatPeriod.LAST_30_DAYS,
            SgtmContainerHitStatPeriod.MONTH,
            SgtmContainerHitStatPeriod.LAST_MONTH,
            SgtmContainerHitStatPeriod.LAST_3_MONTHS,
            SgtmContainerHitStatPeriod.YEAR,
            SgtmContainerHitStatPeriod.CUSTOM
        ];
    };
}
