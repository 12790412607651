import {ApiModel} from "../../../../common/api/model/ApiModel";
import {usePreprod} from "../../../../common/utils/helper";
import {PathHelper} from "../../../../common/utils/PathHelper";
import {pathCmpConfigurationsAnalytics} from "../../../../utils/constants";
import {Status} from "../../../../utils/Status";
import {ContentElement} from "../../../interface/ContentElement";
import {CmpConfigField} from "./CmpConfigField";
import {CmpConfigSettings} from "./CmpConfigSettings";
import {CmpConfigStep} from "./CmpConfigStep";
import {CmpConfigVersion} from "./CmpConfigVersion";
import {CmpCloudflareEndpoint} from "../cloudflare/CmpCloudflareEndpoint";
import {CmpConfigThemeModeField} from "./theme/CmpConfigThemeModeField";
import {CmpConfigThemeMode} from "./theme/CmpConfigThemeMode";
import {Module} from "../../../../utils/Module";
import {CmpConfigCapping} from "./CmpConfigCapping";
import {CmpConfigCookieWall} from "./CmpConfigCookieWall";
import {CmpConfigCCPA} from "./CmpConfigCCPA";
import {CmpConfigExternal} from "./CmpConfigExternal";
import {CmpConfigProperties} from "./CmpConfigProperties";

export class CmpConfig extends ApiModel {
    id: string = "";
    id_partner: number = 0;
    name: string = "";
    version: string = CmpConfigVersion.DEFAULT.name;
    settings: CmpConfigSettings = new CmpConfigSettings();
    domain: string = "";
    status: string = Status.INACTIVE.name;
    step: CmpConfigStep = CmpConfigStep.INFO;
    creation_time: string = "";
    last_update: string = "";
    properties: CmpConfigProperties = {};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === CmpConfigField.SETTINGS) {
                    this[key] = new CmpConfigSettings(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            version: this.version,
            settings: this.settings.getJsonParameters(),
            domain: this.domain,
            status: this.status,
            step: this.step,
            properties: this.properties
        };
    }

    getRoute(): string {
        return Module.CMP_CONFIGURATIONS.buildRoute(this.id);
    }

    getRouteAnalytics(): string {
        return PathHelper.buildPathWithId(pathCmpConfigurationsAnalytics, this.id);
    }

    getStatus(): Status {
        if (this.status === Status.ACTIVE.name) {
            return Status.ACTIVE;
        } else if (this.status === Status.BLOCKED.name) {
            return Status.BLOCKED;
        } else if (this.status === Status.INACTIVE.name && !!this.step) {
            return Status.IN_PROGRESS;
        } else {
            return Status.INACTIVE;
        }
    }

    toContentElement(): ContentElement {
        const element = new ContentElement(this.id, `${this.name}`);
        element.status = this.getStatus();
        element.createdAt = new Date(this.creation_time);
        element.lastUpdated = new Date(this.last_update);
        return element;
    }

    getPreviewMessage(forDarkMode?: boolean): {} {
        const settings = new CmpConfigSettings(this.settings);
        const previewThemeMode = new CmpConfigThemeMode();
        previewThemeMode.backgroundColor = settings.theme.getColor(CmpConfigThemeModeField.BACKGROUND_COLOR, forDarkMode);
        previewThemeMode.mainColor = settings.theme.getColor(CmpConfigThemeModeField.MAIN_COLOR, forDarkMode);
        previewThemeMode.titleColor = settings.theme.getColor(CmpConfigThemeModeField.TITLE_COLOR, forDarkMode);
        previewThemeMode.textColor = settings.theme.getColor(CmpConfigThemeModeField.TEXT_COLOR, forDarkMode);
        previewThemeMode.borderColor = settings.theme.getColor(CmpConfigThemeModeField.BORDER_COLOR, forDarkMode);
        previewThemeMode.overlayColor = settings.theme.getColor(CmpConfigThemeModeField.OVERLAY_COLOR, forDarkMode);
        previewThemeMode.logo = settings.theme.getCssImage(CmpConfigThemeModeField.LOGO, forDarkMode);
        previewThemeMode.watermark = settings.theme.getCssImage(CmpConfigThemeModeField.WATERMARK, forDarkMode);
        previewThemeMode.skin = settings.theme.getCssImage(CmpConfigThemeModeField.SKIN, forDarkMode);

        const providerLogo = `url(${forDarkMode ? "https://ui.consentframework.com/logo-2021-w.svg" : "https://ui.consentframework.com/logo-2021-m.svg"})`;

        return {
            cmp: {
                ...this.settings,
                language: this.settings.language,
                scope: this.settings.scope,
                texts: this.settings.getTextsJson(),
                theme: {
                    ...this.settings.theme.getJsonParameters(),
                    lightMode: {
                        ...previewThemeMode,
                        providerLogo
                    },
                    darkMode: {
                        ...previewThemeMode,
                        providerLogo
                    },
                    toolbar: {
                        ...this.settings.theme.toolbar.getJsonParameters(),
                        active: this.settings.theme.toolbar.active
                    }
                },
                vendorList: this.settings.vendorList.getJsonParameters(),
                publisherPurposes: this.settings.publisherPurposes?.getJsonParameters(),
                forceGdpr: false,
                defaultLanguage: "",
                tcStringLocation: "",
                cookieDomain: "",
                domainList: "",
                capping: CmpConfigCapping.CHECK,
                cookieWall: new CmpConfigCookieWall().getJsonParameters(),
                ccpa: new CmpConfigCCPA().getJsonParameters(),
                external: new CmpConfigExternal().getJsonParameters()
            }
        };
    }

    getConfigVersion(): CmpConfigVersion {
        return CmpConfigVersion.getByName(this.version) || CmpConfigVersion.DEFAULT;
    }

    getScriptUrl(endpoint: CmpCloudflareEndpoint, type: "stub" | "cmp"): string {
        const root = endpoint.getUrl(usePreprod);
        return `${root}/js/pa/${this.id_partner}/c/${this.id}/${type}`;
    }

    getStubUrl(endpoint: CmpCloudflareEndpoint): string {
        return this.getScriptUrl(endpoint, "stub");
    }

    getCmpUrl(endpoint: CmpCloudflareEndpoint): string {
        return this.getScriptUrl(endpoint, "cmp");
    }

    getWebScript(): string {
        const rows = [];
        rows.push(`<script type="text/javascript" src="${this.getStubUrl(CmpCloudflareEndpoint.CACHE)}"></script>`);
        rows.push(`<script type="text/javascript" src="${this.getCmpUrl(CmpCloudflareEndpoint.CHOICES)}" async></script>`);
        return rows.join("\n");
    }

    getAmpScript(): string {
        const rows = [];
        rows.push("<amp-consent id=\"consent\" layout=\"nodisplay\" type=\"sirdata\">");
        rows.push("<script type=\"application/json\">");
        rows.push("    {");
        rows.push("        \"postPromptUI\": \"promptConsentUI\",");
        rows.push(`        "clientConfig": {"type": "TCF_V2", "info": {"pa": ${this.id_partner}, "c": "${this.id}"}}`);
        rows.push("    }");
        rows.push("</script>");
        rows.push("</amp-consent>");
        return rows.join("\n");
    }
}
