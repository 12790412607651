import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CategoryType} from "./CategoryType";

export class Category extends ApiModel {
    id: number = 0;
    id_group: number = 0;
    type: string = CategoryType.INTENT.name;
    active: boolean = true;
    public: boolean = true;
    price: number = 0;
    price_usd: number = 0;
    price_gbp: number = 0;
    id_parent?: number;
    name: string = "";
    code_name: string = "";
    population?: number;

    static MAX_PRICE_VALUE = 250;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    get fullName(): string {
        return this.code_name.replaceAll("/", " > ");
    }

    getJsonParameters(): {} {
        return {
            id_group: this.id_group,
            type: this.type,
            active: +this.active,
            public: +this.public,
            price: this.price,
            price_usd: this.price_usd,
            price_gbp: this.price_gbp
        };
    }
}
