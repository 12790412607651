import {Box, BoxProps, BoxRadius, Color, FlexContent, FlexContentDirection, FlexContentMobileDirection, FlexContentSpacing, Icon} from "@sirdata/ui-lib";
import clsx from "clsx";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Currency} from "../../../api/model/currency/Currency";
import {SalesRevenueProperties} from "../../../api/model/revenue/sales-revenue/SalesRevenueProperties";
import {SalesRevenuePropertiesField} from "../../../api/model/revenue/sales-revenue/SalesRevenuePropertiesField";
import {Formatter} from "../../../common/utils/Formatter";
import {TranslationPortalFile} from "../../../utils/constants";

type MonthlyRevenuesPropertiesProps = {
    properties: SalesRevenueProperties;
}

const MonthlyRevenuesProperties: FC<MonthlyRevenuesPropertiesProps> = ({properties}) => {
    const {t: textMonthlyRevenues} = useTranslation(TranslationPortalFile.MONTHLY_REVENUES);

    return (
        <FlexContent
            direction={FlexContentDirection.ROW}
            spacing={FlexContentSpacing.MEDIUM}
            mobileDirection={FlexContentMobileDirection.COLUMN}
        >
            <Box
                {...BoxProps.SECTION_BLOCK}
                radius={BoxRadius.MD}
                cssClass="monthly-revenues__properties__item"
            >
                <div className="monthly-revenues__properties__item__title">
                    <Icon name="trending_up" colorIcon={Color.OCEAN}/>
                    <span className="h3">{textMonthlyRevenues(`properties.${SalesRevenuePropertiesField.TOTAL_REVENUE}`)}</span>
                </div>
                <span className="monthly-revenues__properties__item__total" style={{color: Color.OCEAN}}>
                    {Formatter.formatNumber(Formatter.convertCentsToUnits(properties.total_revenue))}
                    {Currency.EUR.symbol}
                </span>
            </Box>
            <Box
                {...BoxProps.SECTION_BLOCK}
                radius={BoxRadius.MD}
                cssClass="monthly-revenues__properties__item"
            >
                <div className="monthly-revenues__properties__item__title">
                    <Icon name="trending_down" colorIcon={Color.RED}/>
                    <span className="h3">{textMonthlyRevenues(`properties.${SalesRevenuePropertiesField.TOTAL_COST_AMOUNT}`)}</span>
                </div>
                <span className="monthly-revenues__properties__item__total" style={{color: Color.RED}}>
                    {Formatter.formatNumber(Formatter.convertCentsToUnits(properties.total_cost_amount))}
                    {Currency.EUR.symbol}
                </span>
            </Box>
            <Box
                {...BoxProps.SECTION_BLOCK}
                radius={BoxRadius.MD}
                cssClass={clsx("monthly-revenues__properties__item", "monthly-revenues__properties__item--total-margin")}
            >
                <div className="monthly-revenues__properties__item__title">
                    <Icon name="universal_currency_alt" outlined/>
                    <span className="h3">{textMonthlyRevenues(`properties.${SalesRevenuePropertiesField.TOTAL_MARGIN}`)}</span>
                    <span>{textMonthlyRevenues("properties.total_margin_description")}</span>
                </div>
                <span className="monthly-revenues__properties__item__total" style={{color: Color.WHITE}}>
                    {Formatter.formatNumber(Formatter.convertCentsToUnits(properties.total_margin))}
                    {Currency.EUR.symbol}
                </span>
            </Box>
        </FlexContent>
    );
};

export default MonthlyRevenuesProperties;
