import {ApiModel} from "../../../../common/api/model/ApiModel";
import {CmpConsentStatDailyRatings} from "./CmpConsentStatDailyRatings";
import {CmpConsentStatRatings} from "./CmpConsentStatRatings";

export class CmpConsentStatRates extends ApiModel {
    ratings: CmpConsentStatRatings = new CmpConsentStatRatings();
    trends: CmpConsentStatRatings = new CmpConsentStatRatings();
    daily: CmpConsentStatDailyRatings = new CmpConsentStatDailyRatings();
    responses: { [key: string]: number } = {};
    clicks: { [key: string]: number } = {};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        this.loadObject("ratings", CmpConsentStatRatings);
        this.loadObject("trends", CmpConsentStatRatings);
        this.loadObject("daily", CmpConsentStatDailyRatings);
    }

    getTotalResponses() {
        let total = 0;
        for (let key in this.responses) {
            total += this.responses[key];
        }
        return total;
    }

    getTotalClicks() {
        let total = 0;
        for (let key in this.responses) {
            total += this.responses[key];
        }
        return total;
    }
}

