import {ApiModel} from "../../../../../common/api/model/ApiModel";
import {usePreprod} from "../../../../../common/utils/helper";

export class CmpConfigThemeMode extends ApiModel {
    backgroundColor: string = "";
    mainColor: string = "";
    titleColor: string = "";
    textColor: string = "";
    borderColor: string = "";
    overlayColor: string = "";
    logo: string = "";
    watermark: string = "";
    skin: string = "";

    static ACCEPTED_IMAGE_TYPES = [".jpg", ".jpeg", ".png", ".svg"];
    static LOGO_MAX_FILE_SIZE = 56000;
    static SKIN_MAX_FILE_SIZE = 1000000;

    constructor(o?: {}) {
        super(o);
        this.load(o);
        if (o === undefined) {
            this.skin = usePreprod
                ? "https://dev.cdn.abconsent.net/cmp/images/theme/skin/1-cookies.png"
                : "https://cdn.abconsent.net/cmp/images/theme/skin/1-cookies.png";
            this.watermark = usePreprod
                ? "https://dev.cdn.abconsent.net/cmp/images/theme/watermark/1-cookie.png"
                : "https://cdn.abconsent.net/cmp/images/theme/watermark/1-cookie.png";
        }
    }

    withDefaultColors(): CmpConfigThemeMode {
        this.backgroundColor = "#FFFFFF";
        this.mainColor = "#202942";
        this.titleColor = "#202942";
        this.textColor = "#8492A6";
        this.borderColor = "#F0F0F0";
        this.overlayColor = "#F9F9F9";
        return this;
    }

    getJsonParameters(): {} {
        return {
            backgroundColor: this.backgroundColor,
            mainColor: this.mainColor,
            titleColor: this.titleColor,
            textColor: this.textColor,
            borderColor: this.borderColor,
            overlayColor: this.overlayColor,
            logo: this.logo,
            watermark: this.watermark,
            skin: this.skin
        };
    }
}
